// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.
const hostUrl = 'http://localhost:4200'

export const environment = {
  production: false,
  // bfBaseUrl: 'https://brandfolder.com/api',
  // bfWebsiteBaseUrl: 'https://brandfolder.com',
  // bfStaticAssetsBaseUrl: 'https://static-fast.brandfolder.com',
  segmentWriteKey: '3D2vwp7cRNdeQQAR0agYzuEH3oAi3XWA',
  bfBaseUrl: 'https://bf-stage.com/api',
  bfWebsiteBaseUrl: 'https://bf-stage.com',
  bfStaticAssetsBaseUrl: 'https://static-fast.bf-stage.com',
  hostUrl,
  oauthBaseUrl: 'https://oauth2.stage.integration.bf-squads.com',
  oauthClientId: 'workflow-manager-client-stage',
  oauthRedirectUri: hostUrl,
  temporalBaseUrl: 'https://integration-workflows-stage.brandfolder-svc.com/api',
  // temporalBaseUrl: 'http://localhost:8000/api', // use this for local dev against local temporal server
};
