import React, { useState } from 'react';
import classNames from 'classnames';
import {  useSelector } from 'react-redux';
import { BFLabel, BFInput } from '@integration-frontends/common/ui/forms/form-controls/form-controls';
import { BFButton, ButtonType, IconClose, IconSize, IconAdd, Loader } from '@integration-frontends/common/ui';
import { selectCreateClientLoading } from '@integration-frontends/workflow-manager/core/application';
import './create-client-form.scss';

interface CreateClientProps {
  modal?: boolean;
  onClose?: () => void;
  onSubmit?: (clientName: string, bfApiKey: string) => void;
}

export const CreateClientForm = ({ modal, onClose, onSubmit }: CreateClientProps) => {
  const [bfApiKey, setBfApiKey] = useState(null);
  const [clientName, setClientName] = useState(null);
  const loading = useSelector(selectCreateClientLoading);

  return (
    <section className={classNames("create-client-container", { modal })}>
      <section className="create-client-form-container">
        {(modal &&
          <div className="create-client-form-head">
            <div className="flex flex-row add-client"><IconAdd /> Add client</div>
            <IconClose className="cursor-pointer" iconSize={IconSize.Medium} onClick={onClose} />
          </div>
        )}
        {(loading ? (
          <Loader />
        ) : (
          <>
            <div className="create-client-form">
              <BFLabel htmlFor="input-client-name">Client Name</BFLabel>
              <BFInput
                required={true}
                id="input-client-name"
                onChange={(e) => {
                  setClientName(e.target.value);
                }} />

              <BFLabel htmlFor="input-bf-api-key">Client Brandfolder API Key</BFLabel>
              <BFInput
                required={true}
                id="input-bf-api-key"
                onChange={(e) => {
                  setBfApiKey(e.target.value);
                }} />
            </div>
            {(modal &&
              <div className="flex flex-row" style={{ margin: "24px" }}>
                <BFButton
                  buttonType={ButtonType.Secondary}
                  style={{ width: "75px", marginRight: "10px" }}
                  onClick={onClose}>
                  Cancel
                </BFButton>

                <BFButton
                  buttonType={ButtonType.Primary}
                  style={{ width: "75px" }} disabled={!(!!clientName && !!bfApiKey)}
                  onClick={() => onSubmit(clientName, bfApiKey)}>
                  Save
                </BFButton>
              </div>
            )}
          </>
        ))}
      </section>
    </section>
  )
}
