import { inject, injectable } from 'inversify';
import {
  BRANDFOLDER_API_TOKEN,
  BrandfolderApi,
} from '@integration-frontends/common/brandfolder-api';

import { GET_API_KEY_TOKEN, IGetAuthToken } from './model';

import {
  TEMPORAL_API_TOKEN,
  TemporalApi,
} from '@integration-frontends/common/temporal-api';

@injectable()
export class RepoBase {
  @inject(BRANDFOLDER_API_TOKEN)
  protected brandfolderApi: BrandfolderApi;
  @inject(GET_API_KEY_TOKEN)
  protected getApiKey: IGetAuthToken;
  @inject(TEMPORAL_API_TOKEN)
  protected temporalApi: TemporalApi;
}
