import { StateSelector } from "@integration-frontends/common/utils/redux";
import { clientAdapter } from "../entities";
import { Client, SortOptions } from "@integration-frontends/workflow-manager/core/model";
import { createSelector, EntityState } from '@reduxjs/toolkit';

export function createClientSelectors(stateSelector: StateSelector<EntityState<Client>>) {

  const baseSelectors = clientAdapter.getSelectors(stateSelector);

  const workflows = (clientId: string) =>
    createSelector(baseSelectors.selectAll, (clients) => {
      const foundClient: Client = clients.find(client => client.id === clientId);
      return foundClient.workflows;
    })

  const clientById = (clientId: string) =>
    createSelector(baseSelectors.selectAll, (clients) => {
      return clients.find(client => client.id === clientId);
    })

  const filterClients = (clientString: string, sortOptions: SortOptions) =>
    createSelector(baseSelectors.selectAll, (clients) => {
      return clients.filter(client => client.client_name.includes(clientString)).sort((a, b) => {
        if (sortOptions === SortOptions.AZ) {
          return (a.client_name < b.client_name) ? -1 : 1;
        } else if (sortOptions === SortOptions.ZA) {
          return (a.client_name >= b.client_name) ? -1 : 1;
        }
        return 0;
      })
    })

  return {
    ...baseSelectors,
    workflows,
    clientById,
    filterClients,
  }
}
