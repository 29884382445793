import React from 'react';
import './header.scss';
import { NavLink } from 'react-router-dom';

export const HeaderNav = () => {
  return (
    <nav role="navigation" className="nav-bar">
          <HeaderNavLink route="/workflows" title="Workflows" />
          <HeaderNavLink route="/create" title="Add New Workflow" />
    </nav>
  )
}

export const HeaderNavLink = ({route, title}) => {
  return <NavLink className={({ isActive }) => isActive ? 'active nav-link' : 'nav-link'} to={route}>Add New Workflow</NavLink>
}
