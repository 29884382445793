import {
  GetBrandfolderFormInfoBody,
  GetHighspotFormInfoBody,
} from '@integration-frontends/common/temporal-api';

import {
  Brandfolder
} from './brandfolder';

export const FORM_INFO_REPO_TOKEN = 'FORM_INFO_REPO';

// Highspot

export interface HighspotFormInfo {
  collection: { id: string, title: string }[];
}

export interface IFormInfoRepo {
  getBrandfolderFormInfo: (attributes: GetBrandfolderFormInfoBody) => Promise<Brandfolder[]>;
  getHighspotFormInfo: (attributes: GetHighspotFormInfoBody) => Promise<HighspotFormInfo>;
};
