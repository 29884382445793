import React, { useEffect, useState } from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { CreateClientForm } from './create-client-form'
import { Banner, PageHeader } from '../common';
import { BFInput, BFSelect } from '@integration-frontends/common/ui';
import {
  clientEntitySelectors,
  clientsPageEntered,
  selectClientCreateSuccess,
  selectClient,
  createClient,
} from '@integration-frontends/workflow-manager/core/application';

import { SortOptions } from '@integration-frontends/workflow-manager/core/model';

import './clients-page.scss';

export const ClientsPage = () => {
  const dispatch = useDispatch();
  const [createClientOpen, setCreateClientOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState(SortOptions.AZ)
  const clients = useSelector(clientEntitySelectors.filterClients(search, sort));
  const createClientSuccess = useSelector(selectClientCreateSuccess);

  useEffect(() => {
    dispatch(clientsPageEntered());
  }, [])

  useEffect(() => {
    if (createClientSuccess) {
      setCreateClientOpen(false);
    }
  }, [createClientSuccess])


  const onSubmit = (clientName, bfApiKey) => {
    dispatch(createClient({ clientName, bfApiKey }))
  }

  return (
    <>
      <section className="clients-page">
        <Banner />
        <PageHeader
          onClick={() => setCreateClientOpen(!createClientOpen)}
          title="All clients"
          actionTitle="Add client"
          filtersComponent={
            <>
              <BFInput
                placeholder='Search for a client'
                onChange={(e) => setSearch(e.target.value)}
                style={{width: '100%', margin: '20px', height: '40px', paddingLeft: '20px'}}
              />
              <BFSelect
                options={[
                  {label: 'Sort by: Client (A to Z)', value: SortOptions.AZ},
                  {label: 'Sort by: Client (Z to A)', value: SortOptions.ZA}
                ]}
                value={sort}
                style={{width: '220px'}}
                onOptionChange={({value}) => setSort(SortOptions[`${value}`])}
              />
            </>
          }
        />
        {(createClientOpen &&
          <CreateClientForm
            modal={true}
            onClose={() => setCreateClientOpen(false)}
            onSubmit={onSubmit}
          />
        )}
        <table id="clients-page-table" className="clients-table">
          <tbody>
            <tr className="clients-table-header-row clients-table-row">
              <th>Client Name</th>
              <th>Integrations</th>
              <th>Last Updated</th>
              <th></th>
            </tr>
            {(clients || []).map((client) => (
              <ClientTile client={client} />
            ))}
          </tbody>
        </table>
      </section>
    </>
  )
}

export const ClientTile = ({ client }) => {
  const dispatch = useDispatch();

  const onSelectClient = () => {
    dispatch(selectClient({ clientId: client.id }));
    dispatch(push('/workflows'));
  }

  return (
    <tr className="clients-table-row" onClick={() => onSelectClient()}>
      <td>{client?.client_name}</td>
      <td>{client?.workflows?.length >= 0 ? client.workflows.length : '-'}</td>
      <td>{(new Date()).toDateString()}</td>
      {/* TODO: hidden until ready */}
      {/* <td><MenuClient clientId={client?.id} /></td> */}
    </tr>
  )
}

export interface MenuPopoutProps {
  clientId: string;
}

function Panel({ clientId }: MenuPopoutProps) {
  return (
    <div className="menu-card-popover">
      <a
        className="font-medium menu-card-item"
        target="_blank"
        rel="noopener noreferrer"
        href="#"
      >
        EDIT
      </a>
      <a
        className="font-medium menu-card-item"
        target="_blank"
        rel="noopener noreferrer"
        href="#"
      >
        REMOVE
      </a>
    </div>
  );
}

export interface MenuPopoutProps {
  clientId: string;
}

export function MenuClient({ clientId }: MenuPopoutProps) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [{ name: 'offset', options: { offset: [-6, 4] } }],
    strategy: 'absolute',
  });

  return (
    <Popover data-testid="main-menu" data-cy="popover-menu" style={{ zIndex: 1000 }}>
      <Popover.Button
        as="button"
        ref={setReferenceElement}
        aria-label="open menu"
        className="icon-kebab-menu-container"
      >
      </Popover.Button>
      <Popover.Panel ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        <Panel clientId={clientId} />
      </Popover.Panel>
    </Popover>
  );
}
