import {
  ApiDataResponseError,
  CreateClientBody,
  getResponseDataOrDefault,
} from '@integration-frontends/common/temporal-api';

import {
  Client,
  IClientRepo,
} from '@integration-frontends/workflow-manager/core/model';

import { injectable, inject } from 'inversify';
import { flatten, map, pipe, prop, uniqBy } from 'ramda';
import { RepoBase } from './repo-base';

@injectable()
export class ClientRepo extends RepoBase implements IClientRepo {
  listClients = async (): Promise<Client[]> => {
    return this.temporalApi
      .listClients(await this.getApiKey())
      .then(getResponseDataOrDefault)
      .then((res) => {
        return res.clients;
      });
  };

  createClient =  async (clientBody: CreateClientBody): Promise<Client | ApiDataResponseError> => {
    return await this.temporalApi
      .createClient(await this.getApiKey(), clientBody)
      .then(getResponseDataOrDefault)
      .then((res) => {
        return res
      });
  }
}
