import { withPayloadType } from '@integration-frontends/common/utils/redux';
import { createAction } from '@reduxjs/toolkit';

export const createCredential = createAction('CREATE_CREDENTIAL');

export const enteredCredentialPage = createAction('ENTERED_CREDENTIAL_PAGE');

export const credentialLoading = createAction('CREDENTIAL_LOADING');
export const credentialSuccess = createAction('CREDENTIAL_SUCCESS');
export const credentialFailure = createAction('CREDENTIAL_FAILURE');

// HIGH SPOT

export const highspotSetClientKey = createAction(
  'HIGHSPOT_SET_CLIENT_KEY',
  withPayloadType<{
    clientKey: string;
  }>(),
);
export const highspotSetClientSecret = createAction(
  'HIGHSPOT_SET_CLIENT_SECRET',
  withPayloadType<{
    clientSecret: string;
  }>(),
);

// GETTY

export const gettySetClientKey = createAction(
  'GETTY_SET_CLIENT_KEY',
  withPayloadType<{
    clientKey: string;
  }>(),
);
export const gettySetClientSecret = createAction(
  'GETTY_SET_CLIENT_SECRET',
  withPayloadType<{
    clientSecret: string;
  }>(),
);
