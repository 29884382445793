import React, { useState } from 'react';
import { IconClose, BFButton, ButtonType, VerticalDividerLine } from '@integration-frontends/common/ui';
import { push } from 'redux-first-history';
import { useDispatch } from 'react-redux';
import { ExitModal } from './exit-modal';

export interface CreateWorkflowNavProps {
  onBack?: () => void;
  onNext?: () => void;
  onNextActionName?: string;
  title: string;
}

export const CreateWorkflowNav = ({onBack, onNext, onNextActionName, title}: CreateWorkflowNavProps) => {
  const dispatch = useDispatch();
  const [closePromptOpen, setClosePromptOpen] = useState(false);
  return(
    <>
      <nav className="create-workflow-nav">
        <div className="create-workflow-nav-left">
          <IconClose onClick={() => setClosePromptOpen(true)} />
          <VerticalDividerLine height={20}/>
          <span>{title}</span>
        </div>
        <div className="create-workflow-nav-right">
        <BFButton onClick={onBack} disabled={!onBack} buttonType={ButtonType.Secondary}>Back</BFButton>
        <BFButton onClick={onNext} disabled={!onNext}>{onNextActionName || 'Next'}</BFButton>
        </div>
      </nav>
      {closePromptOpen && (<ExitModal setOpen={setClosePromptOpen}/>)}
    </>
  )
}
