import { WorkflowsEntitiesState } from './common/entities-state';

import { AppState } from '@integration-frontends/common/app';
import { NotificationsState } from '@integration-frontends/common/notifications';

export interface WorkflowsRootState {
  app: AppState;
  notifications: NotificationsState,
  entitiesStateReducer: WorkflowsEntitiesState;
}
