import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { BFLabel, BFInput } from '@integration-frontends/common/ui/forms/form-controls/form-controls';

import {
  highspotSetClientKey,
  highspotSetClientSecret,
  selectHighspotClientKey,
  selectSelectedIntegrationType,
  selectHighspotClientSecret,
  credentialEntitySelectors,
  selectSelectedCredentialId

} from '@integration-frontends/workflow-manager/core/application';
import { Credential } from '@integration-frontends/workflow-manager/core/model';

interface HighspotCredentialFormProps {
  clientKey: string;
  secretKey: string;
}


export const HighspotCredentialForm = () => {
  const dispatch = useDispatch();

  const clientKey = useSelector(selectHighspotClientKey);
  const clientSecret = useSelector(selectHighspotClientSecret);
  const integrationType = useSelector(selectSelectedIntegrationType);
  const selectedCredentialid = useSelector(selectSelectedCredentialId);
  const selectedCredential = useSelector(credentialEntitySelectors.credentialById(selectedCredentialid));

  useEffect(() => {
    if (integrationType !== null && selectedCredentialid !== null) {
      dispatch(highspotSetClientKey({ clientKey: (selectedCredential as Credential).client_key }));
      dispatch(highspotSetClientSecret({ clientSecret: (selectedCredential as Credential).client_secret }));
    }
  }, [selectedCredentialid]);

  return (
    <>
      <BFLabel htmlFor="select-api-key">Highspot Client Key</BFLabel>
      {selectedCredential
        ? <div className="existing-value-placeholder">
          {clientKey}
        </div>
        : <BFInput
          id="select-api-key"
          value={clientKey}
          onChange={(e) => {
            dispatch(highspotSetClientKey({ clientKey: e.target.value }));
          }}
        />
      }
      <BFLabel htmlFor="select-secret-key">Highspot Client Secret</BFLabel>
      {selectedCredential
        ? <div className="existing-value-placeholder">
          {clientSecret}
        </div>
        : <BFInput
          id="select-secret-key"
          value={clientSecret}
          onChange={(e) => {
            dispatch(highspotSetClientSecret({ clientSecret: e.target.value }));
          }}
        />
      }
    </>
  )
}
