import { call, put, takeEvery, select } from 'typed-redux-saga';
import {
  workflowEntityActions,
  selectSelectedClientId,
} from '@integration-frontends/workflow-manager/core/application';
import {
  WORKFLOW_REPO_TOKEN,
  IWorkflowRepo,
} from '@integration-frontends/workflow-manager/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { pageLoaded, pageLoadError } from '@integration-frontends/common/app';
import { workflowsPageEntered } from '../index';
import { workflowPageLoadError, workflowPageInit, workflowPageLoaded } from '../../create-workflow';

function* handler() {
  yield put(workflowPageInit());
  const selectedClientId = yield select(selectSelectedClientId);
  try {
    const workflowRepo: IWorkflowRepo = DI_CONTAINER.get(WORKFLOW_REPO_TOKEN);
    const workflows = yield call(workflowRepo.listWorkflows, selectedClientId);
    yield put(workflowEntityActions.workflowsReceived(workflows));
    yield put(pageLoaded());
    yield put(workflowPageLoaded());
  } catch (e) {
    yield put(workflowPageLoadError({ error: e.message }));
    yield put(pageLoadError());
  }
}

export function* enteredWorkflowsPageEffects() {
  yield takeEvery(workflowsPageEntered, handler);
}
