import { all, call, put, takeEvery } from 'typed-redux-saga';
import { createNotification, NotificationType } from '@integration-frontends/common/notifications';
import {
  highspotFetchSpots,
  highspotReceiveSpots,
} from '@integration-frontends/workflow-manager/core/application';
import {
  FORM_INFO_REPO_TOKEN,
  IFormInfoRepo,
} from '@integration-frontends/workflow-manager/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { pageLoadError } from '@integration-frontends/common/app';
import {
  workflowFormLoading,
  workflowFormSuccess,
  workflowFormFailure,
} from '../../../../../../../application/src/lib/create-workflow';

function* handler(action: ReturnType<typeof highspotFetchSpots>) {
  try {
    yield put(workflowFormLoading())
    const { apiHost, credentialId } = action.payload;
    const formInfoRepo: IFormInfoRepo = DI_CONTAINER.get(FORM_INFO_REPO_TOKEN);

    const spots = yield call(formInfoRepo.getHighspotFormInfo, { credential_id: credentialId, api_host: apiHost });

    if (spots) {
      yield put(highspotReceiveSpots({ spots: spots.collection }));
      yield put(workflowFormSuccess())
    } else {
      yield put(workflowFormFailure())
      yield put(createNotification({ message: "Failed to fetch Spots", location: 'topLevel', type: NotificationType.Error }));
    }
  } catch (e) {
    yield put(pageLoadError());
    yield put(workflowFormFailure());
  }
}

export function* hightspotFetchSpotsEffects() {
  yield takeEvery(highspotFetchSpots, handler);
}
