import { useDispatch, useSelector } from 'react-redux';
import React, {  useEffect } from 'react';
import { BFSelect, BFSelectOption } from '@integration-frontends/common/ui/forms/form-controls/select';
import { BFLabel } from '@integration-frontends/common/ui/forms/form-controls/form-controls';
import {Loader } from '@integration-frontends/common/ui';
import { CreateWorkflowNav } from './create-workflow-nav';
import { SectionTracker } from './section-tracker';

import {
  setIntegrationType,
  selectSelectedIntegrationType,
  selectActiveSection,
  selectCreateCredentialLoading,
  enteredCredentialPage,
  setSelectedCredentialId,
} from '@integration-frontends/workflow-manager/core/application';

import { HighspotCredentialForm } from './workflow-form-highspot';
import { GettyCredentialForm } from './workflow-form-getty';


interface CredentialFormProps {
  onBack: () => void;
  onNext: () => void;
}


export const CredentialForm = ({ onBack, onNext }: CredentialFormProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(enteredCredentialPage());
  }, []);
  const integrationType = useSelector(selectSelectedIntegrationType);
  const activeSection = useSelector(selectActiveSection);
  const loading = useSelector(selectCreateCredentialLoading);

  const integrationTypes = [
    { label: 'Highspot', value: 'highspot' },
    // will turn on when getty is ready
    { label: 'Getty', value: 'getty' },
  ]

  const onOptionChange = (e: BFSelectOption) => {
    dispatch(setIntegrationType({ integrationType: `${e?.value || null}` }));
    dispatch(setSelectedCredentialId({ credentialId: null }));
  }

  return (
    <>
      <CreateWorkflowNav
        onBack={onBack}
        onNext={onNext}
        title="Integration Info"
      />
      <section className="workflow-wizard-section">
        <SectionTracker activeSection={activeSection} />
        <section className="forms-container">
          {(loading ? (
            <Loader />
          ) : (
            <>
              <h1>Integration Info</h1>
              <BFLabel htmlFor="select-integration-type">Integration Type</BFLabel>
              <BFSelect id="select-integration-type" options={integrationTypes}
                placeholder="Select Integration Type"
                value={integrationTypes.filter((type) => integrationType === type.value)[0]?.value || null}
                onOptionChange={onOptionChange}
              />
              {integrationType === 'highspot' ? <HighspotCredentialForm /> : null}
              {integrationType === 'getty' ? <GettyCredentialForm /> : null}
            </>
          ))}
        </section>
      </section>
    </>
  )
}
