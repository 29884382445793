import React, { useEffect } from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import { ReviewSection, ReviewSectionLine } from '../../common';
import { selectCreateWorkflowSuccess } from '@integration-frontends/workflow-manager/core/application';
import cronstrue from 'cronstrue';

import {
  selectSelectedIntegrationType,
  selectSelectedClientId,
  selectHighspotBrandfolder,
  clientEntitySelectors,
  brandfolderEntitySelectors,
  selectGettyWorkflowName,
  selectBrandfolderFormInfo,
  selectGettyBrandfolder,
  selectGettySection,
  selectGettyCollection,
  selectGettyStartDate,
  selectGettySyncInterval,
} from '@integration-frontends/workflow-manager/core/application';

export const GettyReview = ({setComplete}) => {

  const dispatch = useDispatch();

  const createWorkflowSuccess = useSelector(selectCreateWorkflowSuccess);

  useEffect(() => {
    if (createWorkflowSuccess) {
      dispatch(push('/workflows'));
    }
  }, [createWorkflowSuccess])

  const integrationType = useSelector(selectSelectedIntegrationType);

  const selectedClientId = useSelector(selectSelectedClientId)
  const selectedClient = useSelector((state) => clientEntitySelectors.selectById(state, selectedClientId))

  const selectedBrandfolderId = useSelector(selectGettyBrandfolder)
  const selectedBrandfolder = useSelector((state) => brandfolderEntitySelectors.selectById(state, selectedBrandfolderId))

  const startDate = useSelector(selectGettyStartDate);
  const syncInterval = useSelector(selectGettySyncInterval);

  const sectionId = useSelector(selectGettySection);
  const collectionId = useSelector(selectGettyCollection);

  const sectionName = selectedBrandfolder?.sections.data.find(section => section.id === sectionId)?.name;
  const collectionName = selectedBrandfolder?.collections.data.find(collection => collection.id === collectionId)?.name;

  const source = [selectedBrandfolder?.name, sectionName, collectionName].filter(item => !!item).join(' / ');

  useEffect(() => {
    setComplete(!!(
      selectedClientId &&
      selectedBrandfolderId &&
      startDate &&
      syncInterval
    ))
  }, [
    selectedClientId,
    selectedBrandfolderId,
    startDate,
    syncInterval
  ])

  return (
    <>
      <ReviewSection title="Organization info" >
        <ReviewSectionLine
          title="Client"
          value={<div>{selectedClient.client_name}</div>}
        />
        <ReviewSectionLine
          title="Brandfolder API key"
          value={<div>*********</div>}
        />
      </ReviewSection>

      <ReviewSection title="Integration info" >
        <ReviewSectionLine
          title="Integration"
          value={<div>{integrationType}</div>}
        />
        <ReviewSectionLine
          title="Getty API key"
          value={<div>*********</div>}
        />
        <ReviewSectionLine
          title="Getty secret API key"
          value={<div>*********</div>}
        />
      </ReviewSection>

      <ReviewSection title="Workflow info" >
        <ReviewSectionLine
          title="Start date"
          value={<div>{startDate}</div>}
        />
        <ReviewSectionLine
          title="Sync interval"
          value={<div>{cronstrue.toString(syncInterval)}</div>}
        />
        <ReviewSectionLine
          title="Source (Brandfolder)"
          value={<div>{source}</div>}
        />
      </ReviewSection>
    </>
  )
}
