import {
  WorkflowListItem,
  workflowsMockData,
  createWorkflowMockData,
  IWorkflowRepo,
  HighspotCreateWorkflowBody,
  HighspotWorkflow,
  Workflow,
  CreateWorkflowBody,
} from '@integration-frontends/workflow-manager/core/model';

import { injectable } from 'inversify';

@injectable()
export class WorkflowRepoMock implements IWorkflowRepo {
  listWorkflows(): Promise<WorkflowListItem[]> {
    return Promise.resolve(workflowsMockData);
  }

  createWorkflow(): Promise<Workflow> {
    return Promise.resolve(createWorkflowMockData);
  }

  updateWorkflow(): Promise<Workflow> {
    return Promise.resolve(createWorkflowMockData);
  }
}
