import {
  TEMPORAL_API_TOKEN,
  TemporalApi,
  GetBrandfolderFormInfoBody,
  GetHighspotFormInfoBody,
  getResponseDataOrDefault,
  getResponseIncluded,
  getResponseListDataOrDefault,
} from '@integration-frontends/common/temporal-api';

import {
  Brandfolder,
  IFormInfoRepo,
  HighspotFormInfo,
} from '@integration-frontends/workflow-manager/core/model';

import { injectable, inject } from 'inversify';
import { flatten, map, pipe, prop, uniqBy } from 'ramda';
import { RepoBase } from './repo-base';

@injectable()
export class FormInfoRepo extends RepoBase implements IFormInfoRepo {
  getBrandfolderFormInfo = async (attributes: GetBrandfolderFormInfoBody): Promise<Brandfolder[]> => {
    return this.temporalApi
      .getBrandfolderFormInfo(await this.getApiKey(), attributes)
      .then((res) => {
        return res.brandfolder_info;
      });
  };

  getHighspotFormInfo = async (attributes: GetHighspotFormInfoBody): Promise<HighspotFormInfo> => {
    return this.temporalApi
      .getHighspotFormInfo(await this.getApiKey(), attributes)
      .then((res) => {
        return res.spots;
      });
  };
}
