import {StateSelector} from "@integration-frontends/common/utils/redux";
import {workflowAdapter} from "../entities";
import {WorkflowListItem} from "@integration-frontends/workflow-manager/core/model";
import { createSelector, EntityState } from '@reduxjs/toolkit';

export function createWorkflowSelectors(stateSelector: StateSelector<EntityState<WorkflowListItem>>) {

  const baseSelectors = workflowAdapter.getSelectors(stateSelector);

  return {
    ...baseSelectors,
  }
}
