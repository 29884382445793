import React from 'react';
import './banner.scss';

export const Banner = () => (
  <>
    <div className="workflow-manager-banner">
      <h1 className="main-heading">
        Integrations Workflow Manager
      </h1>
      <div className="subheading">
        Add and manage clients integrations and workflows.
      </div>
      <div className="gradient-rule" />
    </div>
  </>
)
