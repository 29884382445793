import {
  TEMPORAL_API_TOKEN,
  TemporalApi,
  getResponseDataOrDefault,
  getResponseIncluded,
  getResponseListDataOrDefault,
} from '@integration-frontends/common/temporal-api';

import {
  WorkflowListItem,
  IWorkflowRepo,
  CreateWorkflowBody,
  UpdateWorkflowBody,
  Workflow,
} from '@integration-frontends/workflow-manager/core/model';

import { injectable, inject } from 'inversify';
import { flatten, map, pipe, prop, uniqBy } from 'ramda';
import { RepoBase } from './repo-base';

@injectable()
export class WorkflowRepo extends RepoBase implements IWorkflowRepo {
  listWorkflows = async (clientId: string): Promise<WorkflowListItem[]> => {
    return this.temporalApi
      .listWorkflows(await this.getApiKey(), clientId)
      .then(getResponseDataOrDefault)
      .then((res) => {
        return res.workflows;
      });
  };

  createWorkflow = async (clientId: string, body: CreateWorkflowBody): Promise<Workflow> => {
    return this.temporalApi
    .createWorkflow(await this.getApiKey(), clientId, body)
    .then(getResponseDataOrDefault)
    .then((res) => {
      return res;
    });
  }

  updateWorkflow = async (clientId: string, workflowId: string, body: UpdateWorkflowBody): Promise<Workflow> => {
    return this.temporalApi
    .updateWorkflow(await this.getApiKey(), clientId, workflowId, body)
    .then(getResponseDataOrDefault)
    .then((res) => {
      return res;
    });
  }
}
