
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    oauthPageEntered,
} from '@integration-frontends/workflow-manager/core/application';
import { BFButton, PrimaryLogo } from '@integration-frontends/common/ui';
import { loginThunk } from '@integration-frontends/common/auth/core/application';
import { Loader } from '@integration-frontends/common/ui';
import { useAuthState, resetAuthState } from '../lib';

import './oauth-page.scss';

export const OauthPage = () => {
    const authState = useAuthState()

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(oauthPageEntered());
    }, [])

    useEffect(() => {
        if (authState.isAuthenticated) {
            dispatch(loginThunk(authState.user.access_token) as any);
        }
    }, [authState.isAuthenticated])

    const handleSignin = () => {
        void authState.signinRedirect()
    }

    return (
        <section className="oauth-page">
            <PrimaryLogo className="logo" />
            <div className="oauth-content">
                <header className="flex flex-row page-header">
                    <h1 className="page-header-title">{"Integrations Workflow Manager"}</h1>
                </header>
                {(authState.isLoading)
                    ? <Loader />
                    : <>
                        <div className="oauth-card">
                            <header className="flex flex-row">
                                <h1 className="page-header-action">{"Sign in to your account"}</h1>
                            </header>
                            <div className="content-container">
                                {(authState.error)
                                    ? <>
                                        <div className="error-msg">
                                            Oops... {authState.error.message}
                                        </div>
                                        <BFButton className="try-again-button h-full flex flex-row" onClick={() => resetAuthState(authState, dispatch)}>
                                            <div className="flex flex-row">
                                                <div>Try again</div>
                                            </div>
                                        </BFButton>
                                    </>
                                    : (authState.isAuthenticated)
                                        ? <div>Loggin you in...</div>
                                        : <BFButton className="sign-in-button h-full flex flex-row" onClick={() => handleSignin()}>
                                            <div className="flex flex-row">
                                                <div>Sign In</div>
                                            </div>
                                        </BFButton>}
                            </div>
                        </div>
                    </>}
            </div>
        </section>
    )

}
