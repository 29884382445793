import { HighspotSpot } from '@integration-frontends/common/temporal-api';
import { withPayloadType } from '@integration-frontends/common/utils/redux';
import { UpdateWorkflowBody } from '@integration-frontends/workflow-manager/core/model';
import { createAction } from '@reduxjs/toolkit';

export const createClient = createAction(
  'CREATE_CLIENT',
  withPayloadType<{ clientName: string, bfApiKey: string }>(),
)

export const selectClient = createAction(
  'SELECT_CLIENT',
  withPayloadType<{ clientId: string }>(),
);

export const workflowPageInit = createAction('WORKFLOW_PAGE_INIT');
export const workflowPageLoaded = createAction('WORKFLOW_PAGE_LOADED');
export const setIntegrationType = createAction(
  'SELECT_INTEGRATION_TYPE',
  withPayloadType<{ integrationType: string }>(),
);

export const workflowFormLoading = createAction('WORKFLOW_FORM_LOADING');
export const workflowFormSuccess = createAction('WORKFLOW_FORM_SUCCESS');
export const workflowFormFailure = createAction('WORKFLOW_FORM_FAILURE');

export const createWorkflowInitialize = createAction('WORKFLOW_CREATE_INIT');
export const createWorkflowSuccess = createAction('WORKFLOW_CREATE_SUCCESS');
export const createWorkflowFailure = createAction(
  'WORKFLOW_CREATE_FAILURE',
  withPayloadType<{ error: string }>(),
);

export const workflowPageLoadError = createAction(
  'WORKFLOW_PAGE_ERROR',
  withPayloadType<{ error: string }>(),
);

export const setActiveSection = createAction(
  'SET_SECTION',
  withPayloadType<{ activeSection: string }>(),
)

export const createWorkflowInfoFormEntered = createAction('CREATE_WORKFLOW_INFO_FORM_ENTERED');

export const createWorkflow = createAction('CREATE_WORKFLOW');
export const updateWorkflow = createAction(
  'UPDATE_WORKFLOW',
  withPayloadType<{
    workflowId: string,
    totalAssetsAffected: number,
    data: UpdateWorkflowBody
  }>(),
);

export const setSelectedCredentialId = createAction(
  'SET_SELECTED_CREDENTIAL',
  withPayloadType<{ credentialId: string }>(),
);

export const setBrandfolderFormInfo = createAction(
  'SET_BRANDFOLDER_FORM_INFO',
  withPayloadType<{ brandfolderFormInfo: any }>(),
);


// HIGH SPOT

export const highspotSetWorkflowName = createAction(
  'HIGHSPOT_SET_WORKFLOW_NAME',
  withPayloadType<{
    workflowName: string;
  }>(),
);
export const highspotSetBrandfolder = createAction(
  'HIGHSPOT_SET_BRANDFOLDER',
  withPayloadType<{
    brandfolder: string;
  }>(),
);

export const highspotSetSection = createAction(
  'HIGHSPOT_SET_SECTION',
  withPayloadType<{
    section: string;
  }>(),
);

export const highspotSetSelectedSections = createAction(
  'HIGHSPOT_SET_SELECTED_SECTIONS',
  withPayloadType<{
    sections: string[];
  }>(),
);


export const highspotSetSelectedCollections = createAction(
  'HIGHSPOT_SET_SELECTED_COLLECTIONS',
  withPayloadType<{
    collections: string[];
  }>(),
);
export const highspotReceiveSpots = createAction(
  'HIGHSPOT_SET_SPOTS',
  withPayloadType<{
    spots: HighspotSpot[];
  }>(),
);

export const highspotSetSpot = createAction(
  'HIGHSPOT_SET_SPOT',
  withPayloadType<{
    spot: string;
  }>(),
);

export const highspotSetIntegrationID = createAction(
  'HIGHSPOT_SET_INTEGRATION_ID',
  withPayloadType<{
    integrationID: string;
  }>(),
);
export const highspotSetAPIHost = createAction(
  'HIGHSPOT_SET_API_HOST',
  withPayloadType<{
    apiHost: string;
  }>(),
);

export const highspotFetchSpots = createAction(
  'HIGHSPOT_FETCH_SPOTS',
  withPayloadType<{
    apiHost: string;
    credentialId: string;
  }>(),
);


export const highspotSetCredentialId = createAction(
  'HIGHSPOT_SET_CREDENTIAL_ID',
  withPayloadType<{
    credentialId: string;
  }>(),
);


// Getty

export const gettySetWorkflowName = createAction(
  'GETTY_SET_WORKFLOW_NAME',
  withPayloadType<{
    workflowName: string;
  }>(),
);

export const gettySetStartDate = createAction(
  'GETTY_SET_START_DATE',
  withPayloadType<{
    startDate: string;
  }>(),
);

export const gettySetEndDate = createAction(
  'GETTY_SET_END_DATE',
  withPayloadType<{
    endDate: string;
  }>(),
);

export const gettySetSyncInterval = createAction(
  'GETTY_SET_SYNC_INTERVAL',
  withPayloadType<{
    syncInterval: string;
  }>(),
);

export const gettySetSection = createAction(
  'GETTY_SET_SECTION',
  withPayloadType<{
    section: string;
  }>(),
);

export const gettySetCollection = createAction(
  'GETTY_SET_COLLECTION',
  withPayloadType<{
    collection: string;
  }>(),
);

export const gettySetBrandfolder = createAction(
  'GETTY_SET_BRANDFOLDER',
  withPayloadType<{
    brandfolder: string;
  }>(),
);

export const gettySetCredentialId = createAction(
  'GETTY_SET_CREDENTIAL_ID',
  withPayloadType<{
    credentialId: string;
  }>(),
);
