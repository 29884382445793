import React, { useEffect } from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import { ReviewSection, ReviewSectionLine } from '../../common';
import { selectCreateWorkflowSuccess } from '@integration-frontends/workflow-manager/core/application';

import {
  selectSelectedIntegrationType,
  selectCreateCredentialLoading,
  selectSelectedClientId,
  selectHighspotBrandfolder,
  selectHighspotSpot,
  selectHighspotSpotId,
  clientEntitySelectors,
  brandfolderEntitySelectors,
  selectHighspotAPIHost,
  selectHighspotSelectedSectionNames,
  selectHighspotSelectedCollectionNames,
} from '@integration-frontends/workflow-manager/core/application';

export const HighspotReview = ({setComplete}) => {

  const dispatch = useDispatch();

  const createWorkflowSuccess = useSelector(selectCreateWorkflowSuccess);

  useEffect(() => {
    if (createWorkflowSuccess) {
      dispatch(push('/workflows'));
    }
  }, [createWorkflowSuccess])

  const selectedClientId = useSelector(selectSelectedClientId)
  const selectedClient = useSelector((state) => clientEntitySelectors.selectById(state, selectedClientId))

  const selectedBrandfolderId = useSelector(selectHighspotBrandfolder)
  const selectedBrandfolder = useSelector((state) => brandfolderEntitySelectors.selectById(state, selectedBrandfolderId))

  const selectedSpot = useSelector(selectHighspotSpot);
  const selectedApiHost = useSelector(selectHighspotAPIHost);
  const integrationType = useSelector(selectSelectedIntegrationType);

  const sectionNames = useSelector(selectHighspotSelectedSectionNames);
  const collectionNames = useSelector(selectHighspotSelectedCollectionNames);

  useEffect(() => {
    setComplete(!!(
      selectedClientId &&
      selectedBrandfolderId &&
      selectedSpot &&
      selectedApiHost &&
      integrationType
    ))
  }, [
    selectedClientId,
    selectedBrandfolderId,
    selectedSpot,
    selectedApiHost,
    integrationType
  ])

  const source = [selectedBrandfolder.name, ...sectionNames, ...collectionNames].join(' / ');
  return (
    <>
      <ReviewSection title="Organization info" >
        <ReviewSectionLine
          title="Client"
          value={<div>{selectedClient.client_name}</div>}
        />
        <ReviewSectionLine
          title="Brandfolder API key"
          value={<div>*********</div>}
        />
      </ReviewSection>

      <ReviewSection title="Integration info" >
        <ReviewSectionLine
          title="Integration"
          value={<div>{integrationType}</div>}
        />
        <ReviewSectionLine
          title="Highspot API key"
          value={<div>*********</div>}
        />
        <ReviewSectionLine
          title="Highspot secret API key"
          value={<div>*********</div>}
        />
      </ReviewSection>

      <ReviewSection title="Workflow info" >
        <ReviewSectionLine
          title="Source (Brandfolder)"
          value={<div>{source}</div>}
        />
        <ReviewSectionLine
          title="Destination (Highspot)"
          value={<div>{selectedSpot.title}</div>}
        />
        <ReviewSectionLine
          title="API host"
          value={<div>{selectedApiHost}</div>}
        />
      </ReviewSection>
    </>
  )
}
