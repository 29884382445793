import React from 'react';
import { useDispatch } from 'react-redux';
import { BFButton, IconClose, IconTrash } from '@integration-frontends/common/ui';
import { push } from 'redux-first-history';
import './exit-modal.scss'

export const ExitModal = ({setOpen}) => {
  const dispatch = useDispatch();
  return (
    <div className="exit-worfklow-modal-container" onClick={() => setOpen(false)}>

      <div className="exit-workflow-modal">
        <div className="exit-workflow-modal-header">
          <div className="exit-workflow-modal-header-left">
            <IconTrash /> <span> Discard workflow </span>
          </div>
          <div>
            <IconClose onClick={() => setOpen(false)} />
          </div>
        </div>
        <div className="exit-workflow-modal-body">
          Heads up — you’ll lose any workflow info you’ve entered.
        </div>
        <div className="exit-workflow-modal-actions">
          <BFButton onClick={() => dispatch(push('/workflows'))}>Discard workflow</BFButton>
        </div>
      </div>

    </div>
  )
}
