import LogRocket from 'logrocket';
import { Identity } from '@integration-frontends/common/auth/core/model';
import {sendLogRocketIdentity} from '@apps-common/utils';

let logRocketInitialized = false;
if (window.location.toString().includes('https://integration-panel-ui.brandfolder-svc.com/')) {
  const appName = (new URLSearchParams(window.location.search).get('appName') || 'panel-ui').toLowerCase();
  LogRocket.init(`3xwicr/integration-${appName}`, {
    mergeIframes: true,
    console: { shouldAggregateConsoleErrors: true },
    dom: { inputSanitizer: true },
  });
  logRocketInitialized = true;
}

export function identifyLogRocket(identity: Identity) {
  if (logRocketInitialized) {
    sendLogRocketIdentity(identity);
  }
}
