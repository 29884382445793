import React from 'react';
import './review-page.scss';

export interface ReviewSectionProps {
  title: string;
}

export const ReviewSection = ({
  children,
  title,
}) => {
  return (
    <section className="review-section">
      <h2 className="review-section-title">{title}</h2>
      {children}
    </section>
  )
}

export interface ReviewSectionLineProps {
  title: string;
  value: JSX.Element;
}

export const ReviewSectionLine = ({
  title,
  value,
}) => {
  return (
    <div className="review-attribute">
      <div className="review-attribute-title">{title}</div>
      <div className="review-attribute-value">{value}</div>
    </div>
  )
}
