import {
  Brandfolder,
  Client,
  Credential,
  WorkflowListItem,
} from '@integration-frontends/workflow-manager/core/model';
import { EntityState } from '@reduxjs/toolkit';

export interface WorkflowsEntitiesState {
  brandfolders: EntityState<Brandfolder>;
  clients: EntityState<Client>;
  credentials: EntityState<Credential>;
  workflows: EntityState<WorkflowListItem>;
}
