import React, { useEffect, useState } from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '@integration-frontends/common/ui';
import classNames from 'classnames';
import { dateFormatter } from '@integration-frontends/common/utils/date-formatter';

import { PageHeader } from '../common';

import {
  workflowEntitySelectors,
  selectSelectedClientId,
  clientEntitySelectors,
  selectworkflowPageInit,
  selectWorkflowPageLoadError,
  workflowsPageEntered,
  updateWorkflow,
} from '@integration-frontends/workflow-manager/core/application';

import './workflows-page.scss';
import { RowItemMenu } from '../common/row-item-menu';
import { WorkflowListItem, workflowServiceType } from '@integration-frontends/workflow-manager/core/model';

export const WorkflowsPage = () => {
  const dispatch = useDispatch();

  const selectedClientId = useSelector(selectSelectedClientId);
  const selectedClient = useSelector((state) => clientEntitySelectors.selectById(state, selectedClientId))
  const workflows = useSelector(workflowEntitySelectors.selectAll).filter((workflowListItem) => {
    if (workflowListItem.brandfolder_account_id === selectedClient.brandfolder_account_id) {
      return workflowListItem;
    }
  })

  const loading = useSelector(selectworkflowPageInit)
  const error = useSelector(selectWorkflowPageLoadError)

  useEffect(() => {
    dispatch(workflowsPageEntered());
  }, [])


  const goBack = () => {
    dispatch(push('/clients'))
  }

  const headerTitle = (n: number) => `Workflows (${n})`

  return (
    <>
      <section className="workflows-page">
        <a className="back-button" onClick={() => goBack()}>
          <div className="back-arrow"></div>
          <span>
            Back
          </span>
        </a>
        <div className="client-name-header">
          <div className="breadcrumb">
            <span className="back" onClick={() => goBack()}>Clients</span> \ <span>{selectedClient.client_name}</span>
          </div>
          <h1>{selectedClient.client_name}</h1>
        </div>
        <PageHeader
          onClick={() => dispatch(push('create-workflow/app-info'))}
          title={headerTitle(workflows.length)}
          actionTitle="Add workflow"
        />
        <table id="workflows-page-table" className="workflows-table">
          <tbody>
            <tr className="workflows-table-header-row workflows-table-row">
              <th>Integration</th>
              <th>Workflow name</th>
              <th>Brandfolder</th>
              <th>Total assets affected</th>
              <th>Status</th>
              <th>Created</th>
              <th>Last Updated</th>
              <th>Updated by</th>
              <th></th>
            </tr>
            {!loading && (workflows || []).map((workflow) => (
              <WorkflowTile workflow={workflow} />
            ))}
          </tbody>
        </table>
        {error
          ? <div className="workflows-page-error-container">
            <h3>
              Oops, something went wrong!
            </h3>
            <span>{error}</span>
          </div>
          : loading
            ? <Loader />
            : <>
              {!workflows.length && <WorkflowGridEmptyState />}
            </>}
      </section>
    </>
  )
}

function ActiveSignal({ active }) {
  return (
    <div className={classNames('active-signal', { active, inactive: !active })}>
      <span className="signal-dot">&#x2022;</span> {(active ? 'active' : 'inactive')}
    </div>
  )
}

interface WorkflowTileProps {
  workflow: WorkflowListItem;
}

export const WorkflowTile = ({ workflow }: WorkflowTileProps) => {
  const dateFormat = 'MM/DD/YY';
  return (
    <tr className="workflows-table-row">
      <td>{workflow.integration_type}</td>
      <td>{workflow.workflow_name}</td>
      <td>{workflow.brandfolder}</td>
      <td>{workflow.total_assets_affected}</td>
      <td><ActiveSignal active={workflow.active} /></td>
      <td>{dateFormatter(workflow.created_at, dateFormat)}</td>
      <td>{dateFormatter(workflow.updated_at, dateFormat)}</td>
      <td>{workflow.last_updated_by}</td>
      <td><RowItemMenu panel={Panel({ workflow, integrationType: workflow.integration_type as workflowServiceType })} /></td>
    </tr>
  )
}


export interface WorkflowMenuPopoutProps {
  workflow: WorkflowListItem;
  integrationType: workflowServiceType;
}

function Panel({ workflow, integrationType }: WorkflowMenuPopoutProps) {
  const dispatch = useDispatch();

  const toggleActive = () => dispatch(updateWorkflow({
    workflowId: workflow.id,
    totalAssetsAffected: workflow.total_assets_affected,
    data: {
      active: !workflow.active,
      service: integrationType as workflowServiceType,
    }
  }))

  return (
    <div className="menu-card-popover">
      <span
        className="font-medium menu-card-item"
        onClick={toggleActive}
      >
        {workflow.active ? 'Make inactive' : 'Make active'}
      </span>
    </div>
  );
}


export const WorkflowGridEmptyState = () => {
  return (
    <div className="workflows-empty-state-container">
      <h2 className="workflows-empty-state-header">
        No results found
      </h2>
      <div className="workflows-empty-state-text">
        We couldn't find a workflow for this client.
      </div>
    </div>
  )
}
