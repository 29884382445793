import React from 'react';

export const SeismicForm = () => {
  return (
      <div className="flex-grow flex flex-col justify-between min-h-0 relative upload-form-container">
        <div className="flex-grow flex flex-col min-h-0">
        Seismic
        </div>
      </div>
  )
}
