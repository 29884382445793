import { StateSelector } from '@integration-frontends/common/utils/redux';
import { createSelector } from '@reduxjs/toolkit';
import { WorkflowsEntitiesState } from '../model';
import { createClientSelectors } from './client';
import { createBrandfolderSelectors } from './brandfolder';
import { createWorkflowSelectors } from './workflow';
import { createCredentialSelectors } from './credential';

export type WorkflowsEntitiesStateSelector = StateSelector<WorkflowsEntitiesState>;

export const createWorkflowsEntitiesSelectors = (
  stateSelector: WorkflowsEntitiesStateSelector
) => {
  const brandfolderSelectors = createBrandfolderSelectors(
    createSelector(stateSelector, (state) => state.brandfolders),
  );
  const clientSelectors = createClientSelectors(
    createSelector(stateSelector, (state) => state.clients),
  );
  const workflowSelectors = createWorkflowSelectors(
    createSelector(stateSelector, (state) => state.workflows),
  );

  const credentialSelectors = createCredentialSelectors(
    createSelector(stateSelector, (state) => state.credentials),
  );

  return {
    brandfolder: brandfolderSelectors,
    client: clientSelectors,
    workflow: workflowSelectors,
    credential: credentialSelectors,
  };
};

export type WorkflowsEntitiesSelectors = ReturnType<typeof createWorkflowsEntitiesSelectors>;
