import React, { useState, ReactElement } from 'react';
import './row-item-menu.scss';
import { IconKebabMenu } from '@integration-frontends/common/ui';
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';
import classNames from 'classnames';


export interface RowItemMenuProps {
  panel: ReactElement;
}

export function RowItemMenu({ panel }: RowItemMenuProps) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [{ name: 'offset', options: { offset: [-6, 4] } }],
    strategy: 'absolute',
  });

  return (
    <Popover data-testid="main-menu" data-cy="popover-menu" style={{ zIndex: 1000 }}>
      <Popover.Button
        as="button"
        ref={setReferenceElement}
        aria-label="open menu"
      >
        <span className={classNames('icon-kebab-menu-button', { active: !!popperElement })}>
          <IconKebabMenu />
        </span>
      </Popover.Button>
      <Popover.Panel ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        {panel}
      </Popover.Panel>
    </Popover>
  );
}
