import {CreateClientForm} from '../clients-page/create-client-form';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSelectedClientId,
  clientEntitySelectors,
  selectActiveSection,
  clientsPageEntered,
  selectClient,
} from '@integration-frontends/workflow-manager/core/application';
import { CreateWorkflowNav } from './create-workflow-nav';
import { SectionTracker } from './section-tracker';
import { BFSelect } from '@integration-frontends/common/ui/forms/form-controls/select';

interface CreateClientFormSectionProps {
  onNext: () => void;
}

export const CreateClientFormSection = ({onNext}: CreateClientFormSectionProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clientsPageEntered());
  }, [])
  const selectedClientId = useSelector(selectSelectedClientId);

  const clients = useSelector(clientEntitySelectors.selectAll).map(
    client => ({label: client.client_name, value: client.id})
  );
  const [createClientOpen, setCreateClientOpen] = useState(false)

  const activeSection = useSelector(selectActiveSection);
  return(
    <>
      <CreateWorkflowNav
      onNext={(!!selectedClientId && onNext)}
      title="Client Info"
      />
      <section className="workflow-wizard-section">
        <SectionTracker activeSection={activeSection}/>
        <section className="forms-container">
          <h1>Select Client</h1>
          <BFSelect
            placeholder={'Select client'}
            id="select-credentials-id"
            value={selectedClientId}
            options={clients}
            onOptionChange={({value}) => dispatch(selectClient({ clientId: `${value}` }))}
          />

          {(createClientOpen &&
            <CreateClientForm
            modal={true}
            onClose={() => setCreateClientOpen(false)}
            />
          )}
          <div className="add-new-client-button" onClick={() => setCreateClientOpen(true)}>+ Add new client</div>
        </section>
      </section>
    </>
  )
}
