import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import classNames from 'classnames';
import { useParams } from "react-router-dom";
import { BFSelect } from '@integration-frontends/common/ui/forms/form-controls/select';
import { BFLabel, BFInput } from '@integration-frontends/common/ui/forms/form-controls/form-controls';
import { BFCheckbox, BFMultiSelect, BFButton, MultiSelect } from '@integration-frontends/common/ui';
import { CreateWorkflowNav } from '../create-workflow-nav';
import { SectionTracker } from '../section-tracker';
import useDebounce from '@integration-frontends/common/utils/use-debounce';
import { onlyLettersAndNumbersAndSpaces, checkStringLength } from '@integration-frontends/common/utils/validation';

import {
  selectSelectedClientId,
  clientEntitySelectors,
  selectClient,
  selectHighspotWorkflowName,
  highspotSetWorkflowName,
  selectHighspotIntegrationID,
  highspotSetIntegrationID,
  selectHighspotAPIHost,
  highspotSetAPIHost,
  selectActiveSection,
  createWorkflowInfoFormEntered,
  selectBrandfolderFormInfo,
  selectHighspotBrandfolder,
  highspotSetBrandfolder,
  selectHighspotSection,
  highspotSetSection,
  credentialEntitySelectors,
  selectHighspotSpots,
  highspotSetSpot,
  selectHighspotSpotId,
  highspotFetchSpots,
  selectSelectedCredentialId,
  highspotSetSelectedSections,
  highspotSetSelectedCollections,
  selectHighspotSelectedSections,
  selectHighspotSelectedCollections,
  highspotSetCredentialId,
} from '@integration-frontends/workflow-manager/core/application';


import '../create-workflow-form.scss';

interface CreateHighspotWorkflowFormContainerProps {
  setComplete: (value: boolean) => void;
}

export const CreateHighspotWorkflowFormContainer = ({ setComplete }: CreateHighspotWorkflowFormContainerProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(createWorkflowInfoFormEntered());
    dispatch(highspotSetCredentialId({credentialId}))
  }, [])

  const apiHost = useSelector(selectHighspotAPIHost);
  const credentialId = useSelector(selectSelectedCredentialId);

  const debouncedSearch = useDebounce(apiHost, 500);

  useEffect(() => {
    if (debouncedSearch !== 0) {
      dispatch(highspotFetchSpots({credentialId, apiHost}));
    }
  }, [debouncedSearch]);

  const activeSection = useSelector(selectActiveSection);
  const clientId = useSelector(selectSelectedClientId);
  const integrationID = useSelector(selectHighspotIntegrationID);

  const brandfolderFormInfo = useSelector(selectBrandfolderFormInfo);

  const workflowName = useSelector(selectHighspotWorkflowName);
  const bfSources = brandfolderFormInfo?.map((bf) => {
    return {label: bf.name, value: bf.id, sections: bf.sections, collections: bf.collections};
  })
  const bfSource = useSelector(selectHighspotBrandfolder);

  const selectBfSource = (value) => {
    dispatch(highspotSetBrandfolder({brandfolder: value}));
    setSelectedSection(null);
  }
  const selectedSectionIds = useSelector(selectHighspotSelectedSections);
  const selectedCollectionIds = useSelector(selectHighspotSelectedCollections);

  const [selectedSection, setSelectedSection] = useState(null);
  const bfSections = bfSources?.find(bf => bf.value === bfSource)?.sections?.data.map(section => {
    return {label: section.name, value: section.id};
  })

  const bfCollections = bfSources?.find(bf => bf.value === bfSource)?.collections?.data.map(collection => {
    return {label: collection.name, value: collection.id};
  })

  const selectedSections = bfSections?.filter((section) => selectedSectionIds.includes(section.value));
  const selectedCollections = bfCollections?.filter((collection) => selectedCollectionIds.includes(collection.value));

  const selectBfSection = (value) => {
    dispatch(highspotSetSection({section: value}));
  }

  const highspotSection = useSelector(selectHighspotSection);

  const [sectionMapsAmount, setSectionMapsAmount] = useState(1);
  const [collectionMapsAmount, setCollectionMapsAmount] = useState(1);

  const [clientName, setClientName] = useState(null);
  const clients = useSelector(clientEntitySelectors.selectAll);
  const credentials = useSelector(credentialEntitySelectors.credentialsByType('highspot'));
  const [collectionToFolderMap, setCollectionToFolderMap] = useState({});

  const [secretId, setSecretId] = useState(null);

  const spots = useSelector(selectHighspotSpots);
  const spotId = useSelector(selectHighspotSpotId);

  const handleMultiChangeSections = (values) => {
    dispatch(highspotSetSelectedSections({sections: values || [] }));
  };

  const handleMultiChangeCollections = (values) => {
    dispatch(highspotSetSelectedCollections({collections: values || [] }));
  };

  useEffect(() => {
    setComplete(!!(
      workflowName &&
      checkStringLength(10, 50, workflowName) &&
      onlyLettersAndNumbersAndSpaces(workflowName) &&
      bfSource &&
      apiHost &&
      integrationID &&
      spotId
    ))
  }, [
    workflowName,
    bfSource,
    apiHost,
    integrationID,
    spotId
  ])

  return (
    <>
      <section className="forms-container">
        <h1>Workflow Info</h1>

        <BFLabel htmlFor="select-api-host">Workflow Name</BFLabel>
        <BFInput
        id="select-workflow-name"
        value={workflowName}
        maxLength={50}
        onChange={(e) => {
          dispatch(highspotSetWorkflowName({ workflowName: e.target.value }));
        }}/>

        <section className="source-section workflow-info-section">
        <header className="workflow-info-section-header">
          Source Brandfolder
        </header>
          <section className="workflow-info-section-body">
            <BFLabel htmlFor="select-brandfolder">Brandfolder</BFLabel>
            <BFSelect
            id="select-brandfolder"
            required={true}
            options={bfSources || []}
            value={bfSources?.find(bf => bf.value === bfSource)?.value}
            onOptionChange={(e) => selectBfSource(e.value)}
            placeholder={"Select Brandfolder"}
            />
            <div className="flex flex-col">
            <BFLabel htmlFor="select-sections">Brandfolder sections (optional)</BFLabel>
            <BFMultiSelect
              onClear={() => dispatch(highspotSetSelectedSections({sections: [] }))}
              id="select-sections"
              placeholder={"Select Sections"}
              options={bfSections || []}
              disabled={!bfSource}
              selected={selectedSections}
              selectedValues={selectedSectionIds}
              onChange={handleMultiChangeSections}
              data-testid={`workflow-form-select-sections`}
              panelStyleOptions={{width: "400px"}}
              buttonStyleOptions={{width: "400px"}}
              getOptionKey={(o) => o.value}
              zIndex={2}
            />
            </div>
            <div className="flex flex-col">
            <BFLabel htmlFor="select-sections">Brandfolder collections (optional)</BFLabel>
            <BFMultiSelect
              onClear={() => dispatch(highspotSetSelectedCollections({collections: [] }))}
              id="select-collections"
              placeholder={"Select Collections"}
              options={bfCollections || []}
              disabled={!bfSource}
              selected={selectedCollections}
              selectedValues={selectedCollectionIds}
              onChange={handleMultiChangeCollections}
              data-testid={`workflow-form-select-collections`}
              getOptionKey={(o) => o.value}
              zIndex={1}
              panelStyleOptions={{width: "400px"}}
              buttonStyleOptions={{width: "400px"}}
            />
            </div>
          </section>
        </section>

        <section className="destination-section workflow-info-section">
          <header className="workflow-info-section-header">
            Destination Highspot
          </header>
          <section className="workflow-info-section-body">
            <BFLabel htmlFor="select-api-host">Api Host</BFLabel>
            <BFInput
            id="select-api-host"
            value={apiHost}
            onChange={(e) => {
              dispatch(highspotSetAPIHost({ apiHost: e.target.value }));
            }}/>

            <BFLabel htmlFor="select-integration-id">Integration ID</BFLabel>
            <BFInput
            id="select-api-host"
            value={integrationID}
            onChange={(e) => {
              dispatch(highspotSetIntegrationID({ integrationID: e.target.value }));
            }}/>

            <BFLabel htmlFor="select-spot-id">The Spot</BFLabel>
            <BFSelect
            id="select-spot-id"
            options={spots.map(spot => ({value: spot.id, label: spot.title}))}
            disabled={!spots.length}
            placeholder={"Choose Spot"}
            value={spotId}
            onOptionChange={(e) => dispatch(highspotSetSpot({spot: `${e?.value || ''}`}))}
            />

          </ section>
        </section>
      </section>
    </>
  );
}
