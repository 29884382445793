import {
  Brandfolder,
  Collection,
  Organization,
  Section,
  Client,
  CollectionData,
  HighspotFormInfo,
  Workflow,
  HighspotWorkflow,
} from '@integration-frontends/workflow-manager/core/model';

import {
  DimensionType,
  ImageType,
  ResourceType,
} from '@integration-frontends/integration/core/model';



export const clientsMockData: Client[] = [
  {
    brandfolder_account_id: 0,
    client_name: "Client1",
    id: "1",
    workflows: [
      { salsify: "1" },
      { salsify: "2" },
      { highspot: "3" },
      { highspot: "4" }
    ]
  },
  {
    brandfolder_account_id: 0,
    client_name: "Client2",
    id: "2",
    workflows: [
      { salsify: "1" },
      { salsify: "2" },
      { highspot: "3" },
      { highspot: "4" }
    ]
  },
  {
    brandfolder_account_id: 0,
    client_name: "Client3",
    id: "3",
    workflows: [
      { salsify: "1" },
      { salsify: "2" },
      { highspot: "3" },
      { highspot: "4" }
    ]
  }
];

export const workflowsMockData = [
  {
    id: "b811bc0b-2cb6-402e-92c4-094569540a8b",
    brandfolder_account_id: 42,
    workflow_name: "Travis Cashion",
    integration_type: "salsify",
    active: true,
    total_assets_affected: 5,
    brandfolder: "asdf",
    created_at: "2022-12-12T23:47:29.676786Z",
    updated_at: "2022-12-12T23:47:29.676786Z",
    last_updated_by: "Travis Cashion"
  },
  {
    brandfolder: "Brandfolder2",
    brandfolder_account_id: 0,
    workflow_name: "Client2",
    id: "2",
    integration_type: "salsify",
    active: true,
    total_assets_affected: 300,
    created_at: "2022-12-12T23:47:29.676786Z",
    updated_at: "2022-12-12T23:47:29.676786Z",
    last_updated_by: "Travis Cashion"
  },
  {
    brandfolder: "Brandfolder3",
    brandfolder_account_id: 0,
    workflow_name: "Client3",
    id: "3",
    integration_type: "highspot",
    active: false,
    total_assets_affected: 300,
    created_at: "2022-12-12T23:23:29.676786Z",
    updated_at: "2022-12-14T23:24:45.676786Z",
    last_updated_by: "Travis Cashion"
  }
]

export const credentialsMockData = [
  {
    access_token: "accesstoken 1",
    api_key: "apiKey1",
    brandfolder_account_id: 0,
    client_key: "clientkey1",
    client_secret: "client secret 1",
    created_at: "string",
    credential_type: "highspot",
    email: "email@email.com",
    expires_at: "string",
    external_user_id: "string",
    id: "abc123",
    refresh_token: "asdfawef",
    scopes: "string",
    updated_at: "string"
  }, {
    access_token: "accesstoken 2",
    api_key: "apiKey2",
    brandfolder_account_id: 0,
    client_key: "clientkey2",
    client_secret: "client secret 2",
    created_at: "string",
    credential_type: "highspot",
    email: "email@email.com",
    expires_at: "string",
    external_user_id: "string",
    id: "abc223",
    refresh_token: "asdfaasdwef",
    scopes: "string",
    updated_at: "string"
  }, {
    access_token: "accesstoken 2",
    api_key: "apiKey2",
    brandfolder_account_id: 0,
    client_key: "clientkey2",
    client_secret: "client secret 2",
    created_at: "string",
    credential_type: "getty",
    email: "email@email.com",
    expires_at: "string",
    external_user_id: "string",
    id: "abc223",
    refresh_token: "asdfaasdwef",
    scopes: "string",
    updated_at: "string"
  },
];

export const brandolderFormInfoMockData: Brandfolder[] = [
  {
    collections: {
      data: [
        {
          id: "1",
          name: "BF 1 Collection 1"
        }
      ]
    },
    id: "1",
    name: "Brandfolder 1",
    sections: {
      data: [
        {
          id: "1",
          name: "BF 1 Section 1"
        }, {
          id: "2",
          name: "BF 1 Section 2"
        }, {
          id: "3",
          name: "BF 1 Section 3"
        }
      ]
    }
  }, {
    collections: {
      data: [
        {
          id: "1",
          name: "BF 2 Collection 1"
        },{
          id: "2",
          name: "BF 2 Collection 2"
        }
      ]
    },
    id: "2",
    name: "Brandfolder 2",
    sections: {
      data: [
        {
          id: "1",
          name: "BF 2 Section 1"
        }
      ]
    }
  }
];

export const highspotFormInfoMockData: HighspotFormInfo = {
  collection: [
      {
        id: "1",
        title: "Highspot Collection"
      }
    ]
}

export const createWorkflowMockData: Workflow = {
  active: {
    bool: true,
    valid: true,
  },
  apiHost: "https://api.highspot.com/v0.5",
  bfSourceKey: "brandfolderkey1",
  brandfolderAccountId: "branderfolder-asdf",
  collectionToFolderMap: {
    collection1: "folder1",
    collection2: "folder2",
    collection3: "folder3",
  },
  createdAt: "string",
  credentialId: "credential1",
  id: "string",
  integrationId: "highspot",
  lastUpdatedBy: "Highspot User 1",
  sectionToFolderMap: {
    section1: "folder1",
    section2: "folder2",
    section3: "folder3"
  },
  spotId: "1",
  updatedAt: "Fri Jan 20 2023",
  workflowName: "New Workflow"
}
