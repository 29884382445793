import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { BFSelect } from '@integration-frontends/common/ui/forms/form-controls/select';
import { BFLabel, BFInput } from '@integration-frontends/common/ui/forms/form-controls/form-controls';
import { BFCheckbox, BFMultiSelect, BFButton, Loader } from '@integration-frontends/common/ui';
import { CreateWorkflowNav } from './create-workflow-nav';
import { SectionTracker } from './section-tracker';
import {HighspotReview} from './workflow-form-highspot';
import {GettyReview} from './workflow-form-getty';

import {
  setIntegrationType,
  selectHighspotClientKey,
  selectSelectedIntegrationType,
  selectHighspotClientSecret,
  selectActiveSection,
  selectCreateCredentialLoading,
  enteredCredentialPage,
} from '@integration-frontends/workflow-manager/core/application';

interface ReviewPageProps {
  onBack: () => void;
  onNext: () => void;
}

export const ReviewPage = ({onBack, onNext}: ReviewPageProps) => {
  const dispatch = useDispatch();

  const integrationType = useSelector(selectSelectedIntegrationType);
  const activeSection = useSelector(selectActiveSection);
  const loading = useSelector(selectCreateCredentialLoading);
  const [complete, setComplete] = useState(false);

  return (
    <>
      <CreateWorkflowNav
      onBack={onBack}
      onNext={complete ? onNext : null}
      onNextActionName={'Publish Workflow'}
      title="Review"
      />
      <section className="workflow-wizard-section">
        <SectionTracker activeSection={activeSection}/>
        <section className="forms-container">
        {(loading ? (
          <Loader />
        ) : (
          <>
            {integrationType === 'highspot' ? <HighspotReview setComplete={setComplete}/> : null }
            {integrationType === 'getty' ? <GettyReview setComplete={setComplete}/> : null }
          </>
        ))}
        </section>
      </section>
    </>
  )
}
