import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { BFSelect } from '@integration-frontends/common/ui/forms/form-controls/select';
import { BFLabel, BFInput } from '@integration-frontends/common/ui/forms/form-controls/form-controls';
import { BFDatePicker } from '@integration-frontends/common/ui/forms/form-controls/date-picker';
import { onlyLettersAndNumbersAndSpaces, checkStringLength } from '@integration-frontends/common/utils/validation';
import cronTime from "cron-time-generator";

import {
  gettySetWorkflowName,
  selectGettyWorkflowName,
  selectSelectedCredentialId,
  gettySetCredentialId,
  createWorkflowInfoFormEntered,
  selectBrandfolderFormInfo,
  selectGettyBrandfolder,
  gettySetBrandfolder,
  selectGettySection,
  selectGettyCollection,
  gettySetSection,
  gettySetCollection,
  selectGettyStartDate,
  selectGettyEndDate,
  gettySetStartDate,
  gettySetEndDate,
  gettySetSyncInterval,
  selectGettySyncInterval,
} from '@integration-frontends/workflow-manager/core/application';

import '../create-workflow-form.scss';

interface CreateGettyWorkflowFormContainerProps {
  setComplete: (value: boolean) => void;
}

export const CreateGettyWorkflowFormContainer = ({ setComplete }: CreateGettyWorkflowFormContainerProps) => {
  const dispatch = useDispatch();
  const credentialId = useSelector(selectSelectedCredentialId);
  useEffect(() => {
    dispatch(createWorkflowInfoFormEntered());
    dispatch(gettySetCredentialId({credentialId}))
  }, [])

  const cronOptions = [
    {label: "Every Day", value: cronTime.everyDay()},
    {label: "Every Hour", value: cronTime.everyHour()},
    {label: "Every Sunday", value: cronTime.everySunday()},
    {label: "Every Monday", value: cronTime.everyMonday()},
    {label: "Every Tuesday", value: cronTime.everyTuesday()},
    {label: "Every Wednesday", value: cronTime.everyWednesday()},
    {label: "Every Thursday", value: cronTime.everyThursday()},
    {label: "Every Friday", value: cronTime.everyFriday()},
    {label: "Every Saturday", value: cronTime.everySaturday()},
    {label: "Every WeekDay", value: cronTime.everyWeekDay()},
    {label: "Every Weekend", value: cronTime.everyWeekend()},
    {label: "Every Week", value: cronTime.everyWeek()},
  ];

  const brandfolderFormInfo = useSelector(selectBrandfolderFormInfo);

  const startDate = useSelector(selectGettyStartDate);
  const endDate = useSelector(selectGettyEndDate);
  const syncInterval = useSelector(selectGettySyncInterval);

  const workflowName = useSelector(selectGettyWorkflowName);

  const bfSources = brandfolderFormInfo?.map((bf) => {
    return {label: bf.name, value: bf.id, sections: bf.sections, collections: bf.collections};
  })
  const bfSource = useSelector(selectGettyBrandfolder);

  const selectBfSource = (value) => {
    dispatch(gettySetBrandfolder({brandfolder: value}));
    dispatch(gettySetSection({section: ''}));
    dispatch(gettySetCollection({collection: ''}));
  }

  const selectedSection = useSelector(selectGettySection);
  const selectedCollection = useSelector(selectGettyCollection);

  const bfSections = bfSources?.find(bf => bf.value === bfSource)?.sections?.data.map(section => {
    return {label: section.name, value: section.id};
  })

  const bfCollections = bfSources?.find(bf => bf.value === bfSource)?.collections?.data.map(collection => {
    return {label: collection.name, value: collection.id};
  })

  const selectBfSection = (value) => {
    dispatch(gettySetSection({section: value}));
  }

  useEffect(() => {
    setComplete(!!(
      workflowName &&
      checkStringLength(10, 50, workflowName) &&
      onlyLettersAndNumbersAndSpaces(workflowName) &&
      startDate &&
      syncInterval &&
      bfSource
    ))
  }, [
    workflowName,
    startDate,
    syncInterval,
    bfSource
  ])

  return (
    <>
      <section className="forms-container">
        <h1>Workflow Info</h1>

        <BFLabel htmlFor="select-api-host">Workflow Name</BFLabel>
        <BFInput
        id="select-workflow-name"
        value={workflowName}
        maxLength={50}
        onChange={(e) => {
          dispatch(gettySetWorkflowName({ workflowName: e.target.value }));
        }}/>

        <section className="destination-section workflow-info-section">
          <header className="workflow-info-section-header">
            Sync timeframe
          </header>
          <section className="workflow-info-section-body">
          <BFLabel>Start date</BFLabel>
          <BFDatePicker
            id="select-start-date"
            value={startDate}
            onChange={(e) => dispatch(gettySetStartDate({startDate: e.target.value }))}
          />
          <BFLabel>End date</BFLabel>
          <BFDatePicker
            id="select-end-date"
            value={endDate}
            onChange={(e) => dispatch(gettySetEndDate({endDate: e.target.value }))}
          />
          <BFLabel htmlFor="select-brandfolder">Sync interval</BFLabel>
          <BFSelect
            id="select-sync-interval"
            required={true}
            options={cronOptions || []}
            value={syncInterval}
            onOptionChange={(e) => dispatch(gettySetSyncInterval({syncInterval: `${e?.value || '' }` }))}
            placeholder={"Select Sync Interval"}
          />

          </ section>
        </section>

        <section className="source-section workflow-info-section">
        <header className="workflow-info-section-header">
          Destination
        </header>
          <section className="workflow-info-section-body">
            <BFLabel htmlFor="select-brandfolder">Brandfolder</BFLabel>
            <BFSelect
            id="select-brandfolder"
            required={true}
            options={bfSources || []}
            value={bfSources?.find(bf => bf.value === bfSource)?.value}
            onOptionChange={(e) => selectBfSource(e.value)}
            placeholder={"Select Brandfolder"}
            />
            <div className="flex flex-col">
            <BFLabel htmlFor="select-sections">Brandfolder sections (optional)</BFLabel>
            <BFSelect
              id="select-sections"
              placeholder={"Select Sections"}
              options={bfSections || []}
              disabled={!bfSource}
              value={selectedSection || ''}
              onOptionChange={(e) => dispatch(gettySetSection({section: `${e.value}`}))}
              data-testid={`workflow-form-select-setions`}

            />
            </div>
            <div className="flex flex-col">
            <BFLabel htmlFor="select-sections">Brandfolder collections (optional)</BFLabel>
            <BFSelect
              id="select-collections"
              placeholder={"Select Collections"}
              options={bfCollections || []}
              disabled={!bfSource}
              value={selectedCollection || ''}
              onOptionChange={(e) => dispatch(gettySetCollection({collection: `${e.value}`}))}
              data-testid={`workflow-form-select-setions`}

            />
            </div>
          </section>
        </section>


      </section>
    </>
  );
}
