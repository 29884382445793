import {
  AUTHENTICATE_SERVICE_TOKEN,
  IAuthenticateService,
  IDENTITY_STORE_TOKEN,
  IIdentityStore,
} from '@integration-frontends/common/auth/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { call, put } from 'redux-saga/effects';
import { init, initSuccess } from '@integration-frontends/common/app';
import { SAVED_ROUTE_KEY } from './common';
import { take } from 'typed-redux-saga';
import { identifyLogRocket } from './logrocket/logrocketSetup';
import { StorageMethods, useStorage } from '@apps-common/utils';
import { history, persistor } from '../store';
import { sendSegmentIdentifyAction } from '@integration-frontends/common/analytics';
import { push } from 'redux-first-history';

function* handleInit() {
  yield put(sendSegmentIdentifyAction({}));
  yield resolveStartRoute();
  yield put(initSuccess());
}

function* resolveStartRoute() {
  const newParams = new URLSearchParams();
  const apiKeyFromUrlParams = newParams?.get('apikey');
  const token: string = apiKeyFromUrlParams ? apiKeyFromUrlParams : null;
  const identityStore: IIdentityStore = DI_CONTAINER.get(IDENTITY_STORE_TOKEN);
  const authService: IAuthenticateService = DI_CONTAINER.get(AUTHENTICATE_SERVICE_TOKEN);

  if (token) {
    const identity = yield call(authService.authenticate, token);
    yield call(identityStore.set, identity);
  }

  const identity = yield call(identityStore.get);
  const isAuthed = yield call(authService.isAuthenticated, identity?.token);

  if (!isAuthed) {
    yield put(push('/getting-started'));
  } else {
    identifyLogRocket(identity);
    yield put(sendSegmentIdentifyAction({ identity }));

    if (history.location.pathname === '/') {
      yield put(push('/workflows'));
    }
  }
}

export function* initWorkflow() {
  yield take(init);
  yield handleInit();
}
