import { ResourceBase, ResourceType } from '@integration-frontends/workflow-manager/core/model';

export interface Collection {
  id: string;
  name: string;
}

export interface CollectionData {
  data: Collection[];
}

export const COLLECTION_REPO_TOKEN = 'COLLECTION_REPO';
export interface ICollectionRepo {
  getCollection: (
    collectionId: string,
  ) => Promise<Collection>;
  listCollections: () => Promise<Collection[]>;
}
