import {
  ApiDataResponseError,
  CreateClientBody,
} from '@integration-frontends/common/temporal-api';

export interface Client {
  id: string;
  brandfolder_account_id: Number;
  client_name: string;
  workflows: WorkflowMap[];
}

export interface Credential {
  access_token: string,
  api_key: string,
  brandfolder_account_id: number,
  client_key: string,
  client_secret: string,
  created_at: string,
  credential_type: string,
  email: string,
  expires_at: string,
  external_user_id: string,
  id: string,
  refresh_token: string,
  scopes: string,
  updated_at: string
}

export interface WorkflowMap {
  [key: string]: string;
}

export const CLIENT_REPO_TOKEN = 'CLIENT_REPO';
export interface IClientRepo {
  listClients: () => Promise<Client[]>;
  createClient: (clientBody: CreateClientBody) => Promise<Client | ApiDataResponseError>;
}
