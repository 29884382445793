import React from 'react';
import { BFButton } from '@integration-frontends/common/ui';
import './page-header.scss';

export interface PageHeaderProps {
  title: string;
  action: () => void;
  actionTitle: string;
  filtersComponent?: JSX.Element;
}

export const PageHeader = ({ title, onClick, actionTitle, filtersComponent = null }) => (
    <header className="page-header">
      <h1 className="page-header-title">{title}</h1>
      <section className="flex flex-row page-header-body">
        <BFButton className="page-header-action flex flex-row" onClick={onClick}>
          <div className="action-content">
            <div className="add-icon"></div>
            <div>{actionTitle}</div>
          </div>
        </BFButton>
        { filtersComponent ? <div className="page-header-filters">{filtersComponent}</div> : null }
      </section>
    </header>
)
