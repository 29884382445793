import { createSelector } from '@reduxjs/toolkit';
import { CreateCredentialState, CreateCredentialRootState, HighspotCredential, GettyCredential } from './reducer';
import { StateSelector } from '@integration-frontends/common/utils/redux';


const selectCreateCredentialState: StateSelector<CreateCredentialState> = (state: CreateCredentialRootState) => state.createCredentialReducer;
export const selectCreateCredentialLoading = createSelector(selectCreateCredentialState, (state) => state.credentialLoading);

// High Spot
const selectHighspotState: StateSelector<HighspotCredential> = (state: CreateCredentialRootState) => state.createCredentialReducer.highspot;

export const selectHighspotClientKey = createSelector(selectHighspotState, (state) => state.client_key);
export const selectHighspotClientSecret = createSelector(selectHighspotState, (state) => state.client_secret);


// Getty
const selectGettyState: StateSelector<GettyCredential> = (state: CreateCredentialRootState) => state.createCredentialReducer.getty;

export const selectGettyClientKey = createSelector(selectGettyState, (state) => state.client_key);
export const selectGettyClientSecret = createSelector(selectGettyState, (state) => state.client_secret);
