import { call, takeEvery, select, put } from 'typed-redux-saga';
import {
    createWorkflow,
    selectSelectedClientId,
    selectSelectedCredentialId,
    selectHighspotWorkflowName,
    selectHighspotIntegrationID,
    selectHighspotAPIHost,
    selectHighspotBrandfolder,
    selectHighspotSpotId,
    selectGettyState,
    selectHighspotState,
} from '../../index';
import {
    CreateWorkflowBody,
    workflowServiceType,
    WORKFLOW_REPO_TOKEN,
} from '@integration-frontends/workflow-manager/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
    IWorkflowRepo,
    HighspotCreateWorkflowBody,
    GettyCreateWorkflowBody,
    WorkflowListItem
} from '@integration-frontends/workflow-manager/core/model';
import { workflowEntityActions } from '../../../common';
import {
    createWorkflowInitialize,
    createWorkflowFailure,
    createWorkflowSuccess,
    selectSelectedIntegrationType,
    selectHighspotSelectedSections,
    selectHighspotSelectedCollections
} from '@integration-frontends/workflow-manager/core/application';
import { createNotification, NotificationType } from '@integration-frontends/common/notifications';

function* handler(action: ReturnType<typeof createWorkflow>) {
    yield put(createWorkflowInitialize())

    const workflowRepo: IWorkflowRepo = DI_CONTAINER.get(WORKFLOW_REPO_TOKEN);
    const selectedClientId = yield select(selectSelectedClientId);
    const integrationType = yield select(selectSelectedIntegrationType)
    const postBody = yield getBodyForIntegrationType(integrationType)

    const res = yield call(
        workflowRepo.createWorkflow,
        selectedClientId,
        postBody,
    );

    if (res.errors) {
        const errorMsg = res.errors
            ? res.errors.map((error) => error.title + ': ' + error.detail).join(', ')
            : 'Unknown error';
        yield put(createWorkflowFailure({ error: errorMsg }))
        yield put(createNotification({
            message: "Error creating workflow: " + errorMsg,
            location: 'topLevel',
            type: NotificationType.Error
        }));
    } else {
        const workflow: WorkflowListItem = {
            id: res.id,
            workflow_name: res.workflow_name,
            active: res.active,
            brandfolder: res.bf_source_key,
            brandfolder_account_id: res.bf_account_id,
            created_at: res.created_at,
            updated_at: res.updated_at,
            integration_type: res.integration_type,
            last_updated_by: res.last_updated_by,
            total_assets_affected: res.total_assets_affected,
        }
        yield put(workflowEntityActions.workflowReceived(workflow));
        yield put(createWorkflowSuccess())
    }

}

export function* createWorkflowEffects() {
    yield takeEvery(createWorkflow, handler);
}

function* getBodyForIntegrationType(
    integrationType: workflowServiceType
): Generator<any, CreateWorkflowBody, any> {
    const active = false;
    const service = yield select(selectSelectedIntegrationType);

    switch (integrationType) {
        case workflowServiceType.highspot:
            const highspotState: HighspotCreateWorkflowBody = yield select(selectHighspotState);
            const spot_id = yield select(selectHighspotSpotId)
            const selectedSections = yield select(selectHighspotSelectedSections)
            const selectedCollections = yield select(selectHighspotSelectedCollections)
            const section_to_folder_map = {}
            selectedSections.forEach((sectionKey: string) => {
                section_to_folder_map[sectionKey] = spot_id
            })
            const collection_to_folder_map = {}
            selectedCollections.forEach((collectionKey: string) => {
                collection_to_folder_map[collectionKey] = spot_id
            })
            return { ...highspotState, collection_to_folder_map, section_to_folder_map, active, service };
        case workflowServiceType.getty:
            const gettyBody: GettyCreateWorkflowBody = yield select(selectGettyState);
            return { ...gettyBody, active, service };
    }
}
