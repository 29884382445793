import {
  CreateClientBody,
} from '@integration-frontends/common/temporal-api';
import {
  Client,
  Credential,
  WorkflowMap,
  IClientRepo,
  clientsMockData,
} from '@integration-frontends/workflow-manager/core/model';
import { injectable } from 'inversify';

@injectable()
export class ClientRepoMock implements IClientRepo {
  listClients(): Promise<Client[]> {
    return Promise.resolve(clientsMockData);
  };
  createClient(clientBody: CreateClientBody): Promise<Client> {
    return Promise.resolve(clientsMockData[0]);
  };
}
