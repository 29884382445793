import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectSelectedClientId,
  clientEntitySelectors,
  selectActiveSection,
  setActiveSection,
  createCredential,
  createWorkflow,
  selectSelectedIntegrationType,
  selectSelectedCredentialId,
  credentialEntitySelectors,
  setSelectedCredentialId,
} from '@integration-frontends/workflow-manager/core/application';

import { CreateHighspotWorkflowFormContainer } from './workflow-form-highspot';
import { CreateClientFormSection } from './create-client';
import { CredentialForm } from './credential-form';
import { ReviewPage } from './review-page';
import { WorkflowInfoPage } from './workflow-info-page';

import './create-workflow-form.scss';

export const sections = [
  ['client-info', 'Client Info'],
  ['app-info', 'Integration Info'],
  ['workflow-info', 'Workflow Info'],
  ['review', 'Review'],
];

export const CreateWorkflowFormContainer = () => {
  const dispatch = useDispatch();

  const activeSection = useSelector(selectActiveSection);

  const nextSection = () => {
    const curr = sections.findIndex(el => el[0] === activeSection);
    if (curr === sections.length - 1) { return };
    dispatch(setActiveSection({ activeSection: sections[curr + 1][0] }));
  }

  const previousSection = () => {
    const curr = sections.findIndex(el => el[0] === activeSection);
    if (curr === 0) { return };
    dispatch(setActiveSection({ activeSection: sections[curr - 1][0] }));
  }


  const integrationType = useSelector(selectSelectedIntegrationType);
  const selectedClientId = useSelector(selectSelectedClientId)
  const selectedClient = useSelector(clientEntitySelectors.clientById(selectedClientId))
  const selectedCredentialid = useSelector(selectSelectedCredentialId);
  const existingCredentials = useSelector(
    credentialEntitySelectors.credentialsByTypeAndBfAccountId(
      selectedClient.brandfolder_account_id.toString(),
      integrationType,
    )
  );

  useEffect(() => {
    if (integrationType !== null && existingCredentials.length > 0) {
      dispatch(setSelectedCredentialId({ credentialId: existingCredentials[0].id }));
    }
  }, [integrationType]);

  const credentialFormOnNext = selectedCredentialid
    ? () => dispatch(setActiveSection({ activeSection: 'workflow-info' }))
    : () => dispatch(createCredential)

  return (
    <section id="create-workflow-form-container" className="create-workflow-form-container">
      <section className="forms-container">
        {(activeSection === 'client-info') && (
          <CreateClientFormSection onNext={nextSection} />
        )}

      {activeSection === 'app-info' && (
        <CredentialForm onBack={previousSection} onNext={credentialFormOnNext} />
      )}

      {activeSection === 'workflow-info' && (
        <>
        <WorkflowInfoPage onBack={previousSection} onNext={nextSection}/>
        </>
      )}
      {activeSection === 'review' && (
        <ReviewPage
          onBack={previousSection}
          onNext={() => {
            dispatch(createWorkflow());
          }}
        />
      )}
      </section>

    </section>
  );
}
