export interface SalsifyWorkflow {
  active: boolean;
  bfSourceKey: string;
  brandfolderAccountID: Number;
  clientName: string;
  createdAt: string;
  credentialID: string;
  customFieldMap: string;
  destConfig: string;
  id: string;
  identifierMap: string;
  orgID: string;
  updatedAt: string
};

export interface HighspotWorkflow {
  active: {
    bool: boolean;
    valid: boolean;
  };
  apiHost: string;
  bfSourceKey: string;
  brandfolderAccountId: string;
  collectionToFolderMap: {
    [key: string]: string;
  };
  createdAt: string;
  credentialId: string;
  id: string;
  integrationId: string;
  lastUpdatedBy: string;
  sectionToFolderMap: {
    [key: string]: string;
  };
  spotId: string;
  updatedAt: string;
  workflowName: string;
};


export interface WorkflowListItem {
  id: string,
  brandfolder: string,
  brandfolder_account_id: number,
  workflow_name: string,
  integration_type: string,
  active: boolean,
  total_assets_affected: number,
  created_at: string,
  updated_at: string,
  last_updated_by: string
}

export const WORKFLOW_REPO_TOKEN = 'WORKFLOW_REPO';

export type CreateWorkflowBody =
  HighspotCreateWorkflowBody
  | GettyCreateWorkflowBody

export type UpdateWorkflowBody = {
  service: workflowServiceType;
  active?: boolean;
}


export type Workflow =
  HighspotWorkflow
  | SalsifyWorkflow


export enum workflowServiceType {
  highspot = 'highspot',
  getty = 'getty',
  salsify = 'salsify',
  seismic = 'seismic',
  hubspot = 'hubspot',
}

interface BaseWorkflowBody {
  active?: boolean;
  credential_id: string;
  service: workflowServiceType;
}

export interface HighspotCreateWorkflowBody extends BaseWorkflowBody {
  api_host: string;
  bf_source_key: string;
  collection_to_folder_map?: Record<string, string>;
  integration_id: string;
  section_to_folder_map?: Record<string, string>;
  spot_id: string;
  workflow_name: string;
}

export interface GettyCreateWorkflowBody extends BaseWorkflowBody {
  allow_expired_license: boolean;
  bf_destination_collection: string;
  bf_destination_key: string;
  bf_destination_section: string;
  cron_schedule: string;
  sync_end_date: Date;
  sync_start_date: Date;
  tag_template: string;
}

export interface IWorkflowRepo {
  // getWorkflow: () => Promise<SalsifyWorkflow | HighspotWorkflow>;
  listWorkflows: (clientId: string) => Promise<WorkflowListItem[]>;
  createWorkflow: (clientId: string, clientBody: CreateWorkflowBody) => Promise<Workflow>;
  updateWorkflow: (clientId: string, workflowId: string, clientBody: UpdateWorkflowBody) => Promise<Workflow>;
};
