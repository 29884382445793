import { createReducer } from '@reduxjs/toolkit';
import {
  credentialLoading,
  credentialSuccess,
  credentialFailure,
  highspotSetClientKey,
  highspotSetClientSecret,
  gettySetClientKey,
  gettySetClientSecret,
} from './actions';

export interface CreateCredentialState {
  credentialLoading: boolean;
  uploading: boolean;
  highspot: HighspotCredential | null;
  getty: GettyCredential | null;
}

export interface HighspotCredential {
  client_key: string;
  client_secret: string;
}

export interface GettyCredential {
  client_key: string;
  client_secret: string;
}

export const highspotCredentialInitialState: HighspotCredential = {
  client_key: '',
  client_secret: '',
}

export const gettyCredentialInitialState: GettyCredential = {
  client_key: '',
  client_secret: '',
}

export const createCredentialInitialState: CreateCredentialState = {
  credentialLoading: false,
  uploading: false,
  highspot: highspotCredentialInitialState,
  getty: gettyCredentialInitialState,
};

export const createCredentialReducer = createReducer(createCredentialInitialState, (builder) =>
  builder
  .addCase(credentialLoading, (state) => {
    state.credentialLoading = true;
  })
  .addCase(credentialSuccess, state => {
    state.credentialLoading = false;
  })
  .addCase(credentialFailure, (state) => {
    state.credentialLoading = false;
  })

  //Highspot

  .addCase(highspotSetClientKey, (state, action) => {
    state.highspot.client_key = action.payload.clientKey;
  })
  .addCase(highspotSetClientSecret, (state, action) => {
    state.highspot.client_secret = action.payload.clientSecret;
  })

  //Getty

  .addCase(gettySetClientKey, (state, action) => {
    state.getty.client_key = action.payload.clientKey;
  })
  .addCase(gettySetClientSecret, (state, action) => {
    state.getty.client_secret = action.payload.clientSecret;
  })
);

export interface CreateCredentialRootState {
  createCredentialReducer: CreateCredentialState;
}
