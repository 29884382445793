import { call, put, takeEvery, select } from 'typed-redux-saga';
import { createNotification, NotificationType } from '@integration-frontends/common/notifications';
import {
  credentialEntityActions,
  selectSelectedClientId,
  setActiveSection,
  selectHighspotClientSecret,
  selectHighspotClientKey,
  selectGettyClientKey,
  selectGettyClientSecret,
  selectSelectedIntegrationType,
} from '@integration-frontends/workflow-manager/core/application';
import {
  CREDENTIAL_REPO_TOKEN,
  ICredentialRepo,
  workflowServiceType,
} from '@integration-frontends/workflow-manager/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { createCredential, credentialLoading, credentialSuccess, credentialFailure } from '../../index';
import { setSelectedCredentialId } from '../../../create-workflow';
import { CreateCredentialBody, getInitialCredentialBodyState } from '@integration-frontends/common/temporal-api';

function* handler(action: ReturnType<typeof createCredential>) {
  yield put(credentialLoading())
  const integrationType = yield select(selectSelectedIntegrationType);
  const credentialRepo: ICredentialRepo = DI_CONTAINER.get(CREDENTIAL_REPO_TOKEN);
  const selectedClientId = yield select(selectSelectedClientId);

  const credentialPayload = yield getCredentialPayload(integrationType);
  const credential = yield call(credentialRepo.createCredential, selectedClientId, credentialPayload);

  if (credential) {
    yield put(credentialEntityActions.credentialReceived(credential));
    yield put(credentialSuccess());
    yield put(setSelectedCredentialId({ credentialId: credential.id }));
    yield put(setActiveSection({ activeSection: 'workflow-info' }));
  } else {
    yield put(credentialFailure())
    yield put(createNotification({ message: "Error creating credential", location: 'topLevel', type: NotificationType.Error }));
  }
}

export function* createCredentialEffects() {
  yield takeEvery(createCredential, handler);
}

function* getCredentialPayload(integrationType: workflowServiceType
): Generator<any, CreateCredentialBody, any> {
  const payload: CreateCredentialBody = getInitialCredentialBodyState(integrationType);
  switch (integrationType) {
    case workflowServiceType.highspot:
      payload.client_key = yield select(selectHighspotClientKey);
      payload.client_secret = yield select(selectHighspotClientSecret);
      return payload
    case workflowServiceType.getty:
      payload.client_key = yield select(selectGettyClientKey);
      payload.client_secret = yield select(selectGettyClientSecret);
      return payload
  }

  return payload
}