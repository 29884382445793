import { all, fork } from 'typed-redux-saga';
import { authWorkflow } from './auth-workflow';
import { initWorkflow } from './init-workflow';

export function* rootWorkflow() {
  yield initWorkflow();
  yield all([
    fork(authWorkflow),
  ]);
}
