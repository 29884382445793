import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';

import { CreateHighspotWorkflowFormContainer } from './workflow-form-highspot';
import { CreateGettyWorkflowFormContainer } from './workflow-form-getty';
import { CreateWorkflowNav } from './create-workflow-nav';
import { SectionTracker } from './section-tracker';

import {
  selectSelectedIntegrationType,
  selectActiveSection,
  enteredCredentialPage,
} from '@integration-frontends/workflow-manager/core/application';

interface WorkflowInfoPageProps {
  onBack: () => void;
  onNext: () => void;
}

export const WorkflowInfoPage = ({onBack, onNext}: WorkflowInfoPageProps) => {
  const dispatch = useDispatch();

  const integrationType = useSelector(selectSelectedIntegrationType);
  const activeSection = useSelector(selectActiveSection);
  const [complete, setComplete] = useState(false);
  return (
    <>
      <CreateWorkflowNav
      onBack={onBack}
      onNext={complete ? onNext : null}
      title="Workflow Info"
      />
      <section className="workflow-wizard-section">
        <SectionTracker activeSection={activeSection}/>
        <>
          {integrationType === 'highspot' ? <CreateHighspotWorkflowFormContainer setComplete={setComplete}/> : null }
          {integrationType === 'getty' ? <CreateGettyWorkflowFormContainer setComplete={setComplete}/> : null }
        </>
      </section>
    </>
  );
}
