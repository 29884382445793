import {
  TEMPORAL_API_TOKEN,
  TemporalApi,
  CreateCredentialBody,
  getResponseDataOrDefault,
  getResponseIncluded,
  getResponseListDataOrDefault,
} from '@integration-frontends/common/temporal-api';

import {
  Credential,
  ICredentialRepo,
} from '@integration-frontends/workflow-manager/core/model';

import { injectable, inject } from 'inversify';
import { flatten, map, pipe, prop, uniqBy } from 'ramda';
import { RepoBase } from './repo-base';

@injectable()
export class CredentialRepo extends RepoBase implements ICredentialRepo {
  listCredentials = async (clientId: string): Promise<any[]> => {
    return this.temporalApi
      .listCredentials(await this.getApiKey(), clientId)
      .then(getResponseDataOrDefault)
      .then((res) => {
        return res || [];
      });
  };

  createCredential = async (clientId: string, credentialBody: CreateCredentialBody): Promise<any> => {
    return await this.temporalApi
      .createHighspotCredential(await this.getApiKey(), credentialBody, clientId)
      .then(getResponseDataOrDefault)
      .then((res) => {
        return res || null;
      });
  }
}
