import { createSelector } from '@reduxjs/toolkit';
import { CreateWorkflowState, CreateWorkflowRootState, HighspotWorkflow, GettyWorkflow } from './reducer';
import { StateSelector } from '@integration-frontends/common/utils/redux';


const selectCreateWorkflowState: StateSelector<CreateWorkflowState> = (state: CreateWorkflowRootState) => state.createWorkflowReducer;
export const selectworkflowPageInit = createSelector(selectCreateWorkflowState, (state) => state.loading);

export const selectWorkflowPageLoadError = createSelector(selectCreateWorkflowState, (state) => state.pageLoadError);

export const selectCreateWorkflowSuccess = createSelector(selectCreateWorkflowState, (state) => state.createWorkflowSuccess);

export const selectSelectedClientId = createSelector(selectCreateWorkflowState, (state) => state.selectedClientId);

export const selectSelectedCredentialId = createSelector(selectCreateWorkflowState, (state) => state.selectedCredentialId);

export const selectActiveSection = createSelector(selectCreateWorkflowState, (state) => state.activeSection);

export const selectBrandfolderFormInfo = createSelector(selectCreateWorkflowState, (state) => state.brandfolderFormInfo);

export const selectSelectedIntegrationType = createSelector(selectCreateWorkflowState, (state) => state.selectedIntegrationType);


// High Spot
export const selectHighspotState: StateSelector<HighspotWorkflow> = (state: CreateWorkflowRootState) => state.createWorkflowReducer.highspot;

export const selectHighspotWorkflowName = createSelector(selectHighspotState, (state) => state.workflow_name);
export const selectHighspotBrandfolder = createSelector(selectHighspotState, (state) => state.bf_source_key);
export const selectHighspotSection = createSelector(selectHighspotState, (state) => state.selected_section_id);

export const selectHighspotCredentialID = createSelector(selectHighspotState, (state) => state.credential_id);

export const selectHighspotIntegrationID = createSelector(selectHighspotState, (state) => state.integration_id);

export const selectHighspotAPIHost = createSelector(selectHighspotState, (state) => state.api_host);

export const selectHighspotSpots = createSelector(selectHighspotState, (state) => state.spots);
export const selectHighspotSpotId = createSelector(selectHighspotState, (state) => state.spot_id);
export const selectHighspotSpot = createSelector(selectHighspotState, (state) => state.spots.find((spot) => spot.id === state.spot_id));

export const selectHighspotSelectedSections = createSelector(selectHighspotState, (state) => state.selectedSections);
export const selectHighspotSelectedCollections = createSelector(selectHighspotState, (state) => state.selectedCollections);


export const selectHighspotSelectedSectionNames = createSelector(selectCreateWorkflowState, (state) => {
  const sectionIds = state.highspot.selectedSections;
  const brandfolder = state.brandfolderFormInfo.find((brandfolder) => brandfolder.id === state.highspot.bf_source_key);
  return sectionIds.map((id) => brandfolder.sections.data.find((section) => section.id === id).name);
});
export const selectHighspotSelectedCollectionNames = createSelector(selectCreateWorkflowState, (state) => {
  const collectionIds = state.highspot.selectedCollections;
  const brandfolder = state.brandfolderFormInfo.find((brandfolder) => brandfolder.id === state.highspot.bf_source_key);
  return collectionIds.map((id) => brandfolder.collections.data.find((collection) => collection.id === id).name);
});

// Getty
export const selectGettyState: StateSelector<GettyWorkflow> = (state: CreateWorkflowRootState) => state.createWorkflowReducer.getty;

export const selectGettyWorkflowName = createSelector(selectGettyState, (state) => state.workflow_name);

export const selectGettyStartDate= createSelector(selectGettyState, (state) => state.sync_start_date);
export const selectGettyEndDate= createSelector(selectGettyState, (state) => state.sync_end_date);
export const selectGettySyncInterval = createSelector(selectGettyState, (state) => state.cron_schedule);

export const selectGettyBrandfolder = createSelector(selectGettyState, (state) => state.bf_destination_key);
export const selectGettySection = createSelector(selectGettyState, (state) => state.bf_destination_section);
export const selectGettyCollection = createSelector(selectGettyState, (state) => state.bf_destination_collection);
