import { createReducer } from '@reduxjs/toolkit';
import cronTime from "cron-time-generator";
import {
  selectClient,
  workflowPageInit,
  workflowPageLoaded,
  workflowPageLoadError,
  setActiveSection,
  highspotSetWorkflowName,
  highspotSetBrandfolder,
  highspotSetSection,
  highspotSetSpot,
  highspotSetIntegrationID,
  highspotSetAPIHost,
  highspotReceiveSpots,
  highspotSetSelectedSections,
  highspotSetSelectedCollections,
  gettySetWorkflowName,
  gettySetStartDate,
  gettySetEndDate,
  gettySetSyncInterval,
  gettySetSection,
  gettySetCollection,
  gettySetBrandfolder,
  gettySetCredentialId,
  setSelectedCredentialId,
  setBrandfolderFormInfo,
  workflowFormLoading,
  createWorkflowInitialize,
  createWorkflowSuccess,
  createWorkflowFailure,
  workflowFormSuccess,
  workflowFormFailure,
  setIntegrationType,
  highspotSetCredentialId,
} from './actions';

export interface CreateWorkflowState {
  loading: boolean;
  pageLoadError: string;
  createWorkflowError: string;
  createWorkflowSuccess: boolean;
  selectedClientId: string;
  selectedCredentialId: string;
  selectedIntegrationType: string;
  uploading: boolean;
  highspot: HighspotWorkflow;
  getty: GettyWorkflow;
  activeSection: string;
  credential: Credential | null;
  brandfolderFormInfo: any;
}

export interface HighspotWorkflow {
  active: boolean;
  api_host: string;
  bf_source_key: string;
  client_id: string;
  workflow_name: string;
  collection_to_folder_map: {
    [key: string]: string;
  };
  credential_id: string;
  integration_id: string;
  section_to_folder_map: {
    [key: string]: string;
  };
  selectedSections: string[];
  selectedCollections: string[];
  spot_id: string;
  selected_section_id: string;
  spots: any;
}

export const highspotWorkflowInitialState: HighspotWorkflow = {
  active: true,
  api_host: '',
  bf_source_key: null,
  selected_section_id: null,
  client_id: null,
  credential_id: null,
  workflow_name: '',
  collection_to_folder_map: {},
  integration_id: '',
  section_to_folder_map: {},
  selectedSections: [],
  selectedCollections: [],
  spot_id: null,
  spots: [],
}

export interface GettyWorkflow  {
  active: boolean,
  allow_expired_license: boolean,
  bf_destination_collection: string,
  bf_destination_key: string,
  bf_destination_section: string,
  credential_id: string,
  cron_schedule: string,
  sync_start_date: string,
  sync_end_date: string,
  workflow_name: string
}

export const gettyWorkflowInitialState: GettyWorkflow = {
  active: true,
  allow_expired_license: true,
  bf_destination_collection: '',
  bf_destination_key: '',
  bf_destination_section: '',
  credential_id: '',
  cron_schedule: cronTime.everyDay(),
  sync_start_date: '',
  sync_end_date: '2100-01-01',
  workflow_name: '',
}

export const createWorkflowInitialState: CreateWorkflowState = {
  loading: false,
  pageLoadError: "",
  createWorkflowError: "",
  createWorkflowSuccess: false,
  selectedClientId: null,
  selectedCredentialId: null,
  selectedIntegrationType: null,
  uploading: false,
  highspot: highspotWorkflowInitialState,
  getty: gettyWorkflowInitialState,
  credential: null,
  activeSection: 'app-info',
  brandfolderFormInfo: null,
};

export const createWorkflowReducer = createReducer(createWorkflowInitialState, (builder) =>
  builder
    // client
    .addCase(selectClient, (state, action) => {
      state.selectedClientId = action.payload.clientId;
    })

    // workflow page
    .addCase(workflowPageInit, (state) => {
      state.pageLoadError = "";
      state.loading = true;
      state.createWorkflowSuccess = false;
      state.activeSection = 'app-info';
      state.highspot = highspotWorkflowInitialState;
      state.selectedCredentialId = null;
      state.selectedIntegrationType = null;
    })
    .addCase(setIntegrationType, (state, action) => {
      state.selectedIntegrationType = action.payload.integrationType;
    })
    .addCase(workflowPageLoaded, (state) => {
      state.loading = false;
    })
    .addCase(workflowPageLoadError, (state, action) => {
      state.pageLoadError = action.payload.error;
    })

    // workflow wizard
    .addCase(workflowFormLoading, (state) => {
      state.loading = true;
    })
    .addCase(workflowFormSuccess, state => {
      state.loading = false;
    })
    .addCase(workflowFormFailure, state => {
      state.loading = false;
    })
    .addCase(setActiveSection, (state, action) => {
      state.activeSection = action.payload.activeSection;
    })
    .addCase(setSelectedCredentialId, (state, action) => {
      state.selectedCredentialId = action.payload.credentialId;
    })
    .addCase(setBrandfolderFormInfo, (state, action) => {
      state.brandfolderFormInfo = action.payload;
    })

    // publish workflow
    .addCase(createWorkflowInitialize, (state) => {
      state.createWorkflowError = "";
      state.createWorkflowSuccess = false;
      state.loading = false;
    })
    .addCase(createWorkflowSuccess, state => {
      state.loading = false;
      state.createWorkflowSuccess = true;
    })
    .addCase(createWorkflowFailure, (state, action) => {
      state.createWorkflowError = action.payload.error;
      state.loading = false;
    })

    //Highspot
    .addCase(highspotSetWorkflowName, (state, action) => {
      state.highspot.workflow_name = action.payload.workflowName;
    })
    .addCase(highspotSetBrandfolder, (state, action) => {
      state.highspot.bf_source_key = action.payload.brandfolder;
    })
    .addCase(highspotSetSection, (state, action) => {
      state.highspot.selected_section_id = action.payload.section;
    })
    .addCase(highspotSetSpot, (state, action) => {
      state.highspot.spot_id = action.payload.spot;
    })
    .addCase(highspotSetIntegrationID, (state, action) => {
      state.highspot.integration_id = action.payload.integrationID;
    })
    .addCase(highspotSetAPIHost, (state, action) => {
      state.highspot.api_host = action.payload.apiHost;
    })
    .addCase(highspotReceiveSpots, (state, action) => {
      state.highspot.spots = action.payload.spots;
    })
    .addCase(highspotSetSelectedSections, (state, action) => {
      state.highspot.selectedSections = action.payload.sections;
    })
    .addCase(highspotSetSelectedCollections, (state, action) => {
      state.highspot.selectedCollections = action.payload.collections;
    })
    .addCase(highspotSetCredentialId, (state, action) => {
      state.highspot.credential_id = action.payload.credentialId;
    })

    // Getty
    .addCase(gettySetWorkflowName, (state, action) => {
      state.getty.workflow_name = action.payload.workflowName;
    })
    .addCase(gettySetStartDate, (state, action) => {
      state.getty.sync_start_date = action.payload.startDate;
    })
    .addCase(gettySetEndDate, (state, action) => {
      state.getty.sync_end_date = action.payload.endDate;
    })
    .addCase(gettySetSyncInterval, (state, action) => {
      state.getty.cron_schedule = action.payload.syncInterval;
    })
    .addCase(gettySetSection, (state, action) => {
      state.getty.bf_destination_section = action.payload.section;
    })
    .addCase(gettySetCollection, (state, action) => {
      state.getty.bf_destination_collection = action.payload.collection;
    })
    .addCase(gettySetBrandfolder, (state, action) => {
      state.getty.bf_destination_key = action.payload.brandfolder;
    })
    .addCase(gettySetCredentialId, (state, action) => {
      state.getty.credential_id = action.payload.credentialId;
    })
);

export interface CreateWorkflowRootState {
  createWorkflowReducer: CreateWorkflowState;
}
