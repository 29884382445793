import React from 'react';
import { sections } from './create-workflow-form';
import classNames from 'classnames';
import { IconSuccessCheckmark, IconSize } from '@integration-frontends/common/ui';

interface SectionTrackerProps {
  activeSection: string;
}


export const SectionTracker = ({ activeSection }: SectionTrackerProps) => {

  const sectionElements = sections.map(el => <SectionItem sectionName={el[0]} sectionTitle={el[1]} activeSection={activeSection}/>);

  return (
    <section className="tracker-container">
      <ul className="flex flex-col space-between tracker-list-container">
        {sectionElements}
      </ul>
    </section>
  )
}

const SectionItem = ({sectionName, sectionTitle, activeSection}) => {
  const isComplete = (section: string) => {
    return sections.findIndex(el => el[0] === activeSection) > sections.findIndex(el => el[0] === section);
  }
  const isActive = (section: string) => {
    return activeSection === section;
  }
  return (
    <li className="track-item">
      {(isActive(sectionName) && <div className="active-icon tracker-icon"></div>)}
      {(isComplete(sectionName) && <span className="success-icon tracker-icon"><IconSuccessCheckmark iconSize={IconSize.ExtraSmall}/></span>)}
      {((!isActive(sectionName) && !isComplete(sectionName)) && <div className="inactive-icon tracker-icon"></div>)}
      <span className="tracker-item-title">{sectionTitle}</span>
    </li>
  )
}
