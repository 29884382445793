import 'reflect-metadata'; //needs to be first import
import './init'; // needs to be second import

import 'libs/common/ui/src/lib/styles/tailwind.scss';
import React, {StrictMode} from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import i18next from './i18n';


import App from './app/app';

ReactDOM.render(
  <StrictMode>
    <I18nextProvider i18n={i18next}>
        <App />
    </I18nextProvider>,
  </StrictMode>,
  document.getElementById('root') as HTMLElement,
);
