// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { history, store } from './store';
import './app.scss';
import { ToastContainer } from 'react-toastify';


import { Route, Routes, Link, Navigate, unstable_HistoryRouter as Router  } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Provider, useSelector } from 'react-redux';

import {
  CreateWorkflowFormContainer,
  HeaderTitle,
  ClientsPage,
  OauthPage,
  WorkflowsPage,
} from '@integration-frontends/workflow-manager/ui';
import { init } from '@integration-frontends/common/app';
import { AuthProvider } from "react-oidc-context";
import { User } from "oidc-client-ts";
import { environment } from '../environments/environment';

function ConnectedApp() {
  useEffect(() => {
    store.dispatch(init({ name: `integration-workflow-manager` }));
  }, [])

  return (
    <Router history={history}>
      <div />
      <HeaderTitle />
      <section className="admin-ui-container" >
        <Routes>
          <Route
            path="/getting-started"
            element={
              <div id="oauth-container">
                <OauthPage />
              </div>
            }
          />
          <Route
            path="/workflows"
            element={
              <WorkflowsPage />
            }
          />
          <Route
            path="/clients"
            element={
              <ClientsPage />
            }
          />

          <Route
            path="/clients/:id"
            element={
              <ClientsPage />
            }
          />
          <Route
          path="/create-workflow/:entry"
          element={<CreateWorkflowFormContainer />}
          />
        </Routes>
      </section>
    </Router>
  );
}

export function App() {
  const onSigninCallback = (_user: User | void): void => {
    window.history.replaceState(
      {},
      document.title,
      window.location.pathname
    )
  }

  const oidcConfig = {
    authority: environment.oauthBaseUrl,
    client_id: environment.oauthClientId,
    redirect_uri: environment.oauthRedirectUri,
    onSigninCallback: onSigninCallback,
  };

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        enableMultiContainer
        containerId="topLevel"
        className="top-level-toast-container"
      />
      <AuthProvider {...oidcConfig}>
        <Provider store={store}>
          <ConnectedApp />
        </Provider>
      </AuthProvider>
    </>
  );
}

export default App;

