import { call, put, takeEvery, select } from 'typed-redux-saga';
import {
  workflowFormLoading,
  workflowFormSuccess,
  workflowFormFailure,
  createWorkflowInfoFormEntered,
  selectSelectedCredentialId,
  setBrandfolderFormInfo,
} from '../../index';
import {
  FORM_INFO_REPO_TOKEN,
  IFormInfoRepo,
} from '@integration-frontends/workflow-manager/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  brandfolderEntityActions,
} from '@integration-frontends/workflow-manager/core/application';

function* handler(action: ReturnType<typeof createWorkflowInfoFormEntered>) {
  yield put(workflowFormLoading());
  yield put(brandfolderEntityActions.brandfoldersRemoved());
  const formInfoRepo: IFormInfoRepo = DI_CONTAINER.get(FORM_INFO_REPO_TOKEN);
  const credentialId = yield select(selectSelectedCredentialId);
  const formInfo = yield call(formInfoRepo.getBrandfolderFormInfo, { credential_id: credentialId });
  if (formInfo) {
    yield put(setBrandfolderFormInfo(formInfo));
    yield put(brandfolderEntityActions.brandfoldersReceived(formInfo));
    yield put(workflowFormSuccess());
  } else {
    yield put(workflowFormFailure());
  }
}

export function* workflowInfoFormEffects() {
  yield takeEvery(createWorkflowInfoFormEntered, handler);
}
