import React from 'react';
import { IconLogo } from '@integration-frontends/common/ui';
import { logoutThunk } from '@integration-frontends/common/auth/core/application';
import { useDispatch } from 'react-redux';
import { useAuth } from "react-oidc-context";

export const HeaderTitle = () => {
  const auth = useAuth();
  const dispatch = useDispatch();

  const handleSignOut = async () => {
    await auth.removeUser();
    dispatch(logoutThunk() as any);
  }

  return (

    <header className="header">
      <IconLogo />
      <div className="links">
        <span className="sign-out" onClick={() => handleSignOut()} >Sign Out</span>
      </div>
    </header>
  )
}
